import React, { useState, useEffect } from "react";
import { useAppContext } from "../context/AppContext";
import "../styles/ImageUpload.scss";

const ImageUpload = ({ image, setImage, category, active, setActive }) => {
  const { files, setFiles } = useAppContext();
  const [activeTab, setActiveTab] = useState("upload-from-device");
  const [file, setFile] = useState();
  const [uploadingState, setUploadingState] = useState("idle");

  // on upload image from input set the image to the first file
  const handleImageUpload = (e) => {
    setUploadingState("uploading");
    // upload the image to server
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("file", e.target.files[0]);
    fetch("https://cash-registar-server.vercel.app/api/upload", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        const file = {
          title: data.key,
          madeBy: "user",
          type: "image",
          url: "https://cash-registar-server.vercel.app/api/upload/" + data.key,
          category: category,
        };

        // post the file to the server
        fetch("https://cash-registar-server.vercel.app/api/files", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(file),
        })
          .then((res) => res.json())
          .then((data) => {
            setFile(data);
            setUploadingState("uploaded");
          });
      });
  };

  const deleteFile = () => {
    const token = localStorage.getItem("token");
    fetch(`https://cash-registar-server.vercel.app/api/files/${file._id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    fetch(`${file.url}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setFile();
    setUploadingState("idle");
  };

  return (
    <div
      className="upload-image-component"
      style={{ display: active ? "flex" : "none" }}
    >
      <div className="upload-image-component-whole-background"></div>
      <div className="upload-image-header">
        <span>Dodavanje slike</span>
        <button onClick={() => setActive(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="upload-image-body">
        <div className="upload-image-body-navigation">
          <button
            className={
              activeTab === "upload-from-device"
                ? "upload-image-body-navigation-button active"
                : "upload-image-body-navigation-button"
            }
            onClick={() => setActiveTab("upload-from-device")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={0.8}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"
              />
            </svg>
            <span>Moje datoteke</span>
          </button>{" "}
          <button
            className={
              activeTab === "upload-from-web"
                ? "upload-image-body-navigation-button active"
                : "upload-image-body-navigation-button"
            }
            onClick={() => setActiveTab("upload-from-web")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={0.8}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
              />
            </svg>

            <span>Web lokacija</span>
          </button>{" "}
          <button
            className={
              activeTab === "upload-from-app"
                ? "upload-image-body-navigation-button active"
                : "upload-image-body-navigation-button"
            }
            onClick={() => setActiveTab("upload-from-app")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={0.8}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
              />
            </svg>
            <span>Slike u aplikaciji</span>
          </button>
        </div>
        <div
          className="upload-image-body-content"
          style={{
            transform:
              activeTab === "upload-from-device"
                ? "translateX(0)"
                : activeTab === "upload-from-web"
                ? "translateX(-33.333333333333333%)"
                : "translateX(-66.6666666666666666666%)",
          }}
        >
          <div className="upload-image-body-content-upload-from-device">
            {" "}
            <input type="file" onChange={handleImageUpload} />
            {uploadingState === "idle" ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={0.8}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
                  />
                </svg>
                <span>
                  Povucite datoteku ovdje ili kliknite za odabir datoteke
                </span>
                <button className="upload-image-body-content-upload-from-device-button">
                  Odaberi datoteku
                </button>
              </>
            ) : uploadingState === "uploading" ? (
              <div className={"uploading"}>
                <div className="spinner"></div>
              </div>
            ) : (
              <div className="uploaded">
                <img src={file.url} alt={file.title} />
                <span>Slika je uspješno učitana</span>
              </div>
            )}
          </div>
          <div className="upload-image-body-content-upload-from-web">
            <h5>Unesite URL slike koju želite dodati</h5>
            <div className="upload-image-body-content-upload-from-web-row">
              <input
                type="text"
                placeholder="https://www.example.com/image.jpg"
              />
              <button className="upload-image-body-content-upload-from-web-button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                  />
                </svg>
              </button>
            </div>
          </div>{" "}
          <div className="upload-image-body-content-upload-from-app"></div>
        </div>
      </div>
      <div className="upload-image-footer">
        <div className="upload-image-footer-left"></div>
        <div className="upload-image-footer-buttons">
          <button
            className="upload-image-footer-button"
            onClick={() => {
              setActive(false);
              if (file) {
                deleteFile();
              }
            }}
          >
            Odustani
          </button>
          <button
            className="upload-image-footer-button"
            onClick={() => {
              //delete the old image

              setImage(file);
              setActive(false);
              setUploadingState("idle");
            }}
          >
            Spremi
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
