import React, { useState, useEffect } from "react";
import Select from "react-select";
import ImageUpload from "./ImageUpload";
import AreYouSure from "./AreYouSure";

const EditDrink = ({
  drink,
  categories,
  subCategories,
  setDrinks,
  setDrink,
}) => {
  const [editedDrink, setEditedDrink] = useState({
    image: {},
    title: "",
    category: [],
    subCategory: [],
    stock: 0,
    unitSubtraction: "komad",
    prices: [],
    description: "",
    layoutNumber: 0,
  });
  const [optionsCategories, setOptionsCategories] = useState([]);
  const [optionsSubCategories, setOptionsSubCategories] = useState([]);
  const [newPrice, setNewPrice] = useState({ price: "", subtraction: "" });
  const [image, setImage] = useState(null);
  const [uploadImageActive, setUploadImageActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [areYouSure, setAreYouSure] = useState("idle");
  const [areYouSureActive, setAreYouSureActive] = useState(false);

  const getCategoryOptions = (category) => {
    if (!category || categories.length === 0 || category.length === 0)
      return [];
    const options = category?.map((c) => {
      return {
        value: c,
        label: categories.find((cat) => cat._id === c)?.title,
      };
    });
    return options;
  };

  const getSubCategoryOptions = (subCategory) => {
    if (!subCategory || subCategories.length === 0 || subCategory.length === 0)
      return [];
    const options = subCategories?.map((subCat) => {
      return {
        value: subCat,
        label: subCategories.find((sub) => sub._id === subCat)?.title,
      };
    });
    return options;
  };

  const getValuesFromOptions = (options) => {
    const values = options.map((option) => {
      return option.value;
    });
    return values;
  };

  useEffect(() => {
    setEditedDrink({
      _id: drink?._id,
      title: drink?.title || "",
      image: drink?.image || {},
      category: getCategoryOptions(drink?.category),
      subCategory: getSubCategoryOptions(drink?.subCategory),
      stock: drink?.stock || 0,
      unitSubtraction: drink?.unitSubtraction || "komad",
      prices: drink?.prices || [],
      description: drink?.description || "",
      layoutNumber: drink?.layoutNumber || 0,
    });
  }, [drink]);

  useEffect(() => {
    setOptionsCategories(
      categories.map((category) => ({
        value: category?._id,
        label: category?.title,
      }))
    );
  }, [categories]);

  useEffect(() => {
    setOptionsSubCategories(
      subCategories.map((subCategory) => ({
        value: subCategory?._id,
        label: subCategory?.title,
      }))
    );
  }, [subCategories]);

  // Ensure `editedDrink.category` is in the correct format for `react-select`
  useEffect(() => {
    if (drink?.category && categories.length > 0) {
      const selectedCategories = drink.category.map((cat) => ({
        value: cat, // Assuming `cat` is the category id, adjust if necessary
        label: categories.find((c) => c._id === cat)?.title || cat,
      }));
      setEditedDrink((prev) => ({
        ...prev,
        category: selectedCategories,
      }));
    }
  }, [drink?.category, categories]);

  useEffect(() => {
    if (drink?.subCategory && subCategories.length > 0) {
      const selectedSubCategories = drink.subCategory.map((subCat) => ({
        value: subCat, // Assuming `subCat` is the subcategory id, adjust if necessary
        label: subCategories.find((s) => s._id === subCat)?.title || subCat,
      }));
      setEditedDrink((prev) => ({
        ...prev,
        subCategory: selectedSubCategories,
      }));
    }
  }, [drink?.subCategory, subCategories]);

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      borderColor: state.isFocused ? "#D1D5DB" : "gray", // Adjust the colors as needed
      borderRadius: 0,
      borderWidth: "0 0 1px 0",
      boxShadow: "none", // Removes the default shadow
      "&:hover": {
        borderColor: state.isFocused ? "#D1D5DB" : "gray",
      },
      color: "white",

      borderRight: "none",
    }),

    menu: (provided) => ({
      ...provided,
      backgroundColor: "white", // Adjust if you want a different background for the dropdown
      color: "white",
      borderRight: "none",

      //remove the padding
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#000" : "white",
      color: state.isSelected ? "white" : "black",
      cursor: "pointer",
      fontSize: "0.875rem",
      margin: 0,

      "&:hover": {
        backgroundColor: "#eee",
      },
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "#a0a0a0", // Placeholder color, adjust as needed
      fontSize: "0.875rem",
    }),
    input: (provided) => ({
      ...provided,
      color: "white", // Input text color
    }),
    // Add other customizations as needed

    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#2b2b2b",
      };
    },

    //remove the vertical line between the multi values
    multiValueRemove: (styles) => {
      return {
        ...styles,
        width: "1.3rem",
        color: "white",
        strokWidth: "0.8",
        height: "1.3rem",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "black",
          color: "white",
        },
      };
    },

    //;pwer the opacity of the vertical line between values and dropdown indicator
    indicatorSeparator: (styles) => {
      return {
        ...styles,
        backgroundColor: "rgba(255, 255, 255, 0.24)",
      };
    },

    //style the multi value label
    multiValueLabel: (styles) => {
      return {
        ...styles,
        color: "white",
        fontSize: "0.75rem",
      };
    },
  };

  useEffect(() => {
    if (image) {
      if (
        editedDrink?.image?.url &&
        editedDrink?.image?.url !== image?.url &&
        editedDrink?.image?.url !== drink?.image?.url &&
        editedDrink?.image?.madeBy !== "admin"
      ) {
        deleteFile(editedDrink?.image);
      }
      setEditedDrink({ ...editedDrink, image: image });
    }
  }, [image]);

  const deleteFile = (file) => {
    const token = localStorage.getItem("token");
    fetch(`https://cash-registar-server.vercel.app/api/files/${file._id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    fetch(`${file.url}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const fetchDrinks = () => {
    const token = localStorage.getItem("token");
    fetch("https://cash-registar-server.vercel.app/api/drink", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setDrinks(data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  const deleteDrink = () => {
    // Trigger the AreYouSure component to show up.
    setAreYouSureActive(true);
  };

  useEffect(() => {
    if (areYouSure === "true" && drink?._id) {
      performDeletion();
    }
    // Reset the areYouSure state after action is taken.
    setAreYouSure("idle");
  }, [areYouSure]);

  const performDeletion = () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    if (drink?.image?.madeBy !== "admin") {
      deleteFile(drink?.image);
    }

    fetch(`https://cash-registar-server.vercel.app/api/drink/${drink._id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        fetchDrinks();
        setDrink({
          title: "",
          image: {},
          category: [],
          subCategory: [],
          stock: 0,
          unitSubtraction: "komad",
          prices: [],
          description: "",
        });
        setEditedDrink({
          title: "",
          image: {},
          category: [],
          subCategory: [],
          stock: 0,
          unitSubtraction: "komad",
          prices: [],
          description: "",
        });
        setImage({});
        setNewPrice({ price: "", subtraction: "" });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting drink:", error);
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    setLoading(true);

    const token = localStorage.getItem("token");
    e.preventDefault();

    if (editedDrink?.category?.length > 0 && editedDrink?.category[0]?.value) {
      editedDrink.category = getValuesFromOptions(editedDrink.category);
      setEditedDrink({
        ...editedDrink,
        category: editedDrink.category,
      });
    }

    if (
      editedDrink?.subCategory?.length > 0 &&
      editedDrink?.subCategory[0]?.value &&
      subCategories?.length > 0
    ) {
      editedDrink.subCategory = getValuesFromOptions(editedDrink.subCategory);
      setEditedDrink({
        ...editedDrink,
        subCategory: editedDrink.subCategory,
      });
    }

    if (editedDrink?.unitSubtraction === "komad") {
      editedDrink.prices = editedDrink.prices.map((price) => {
        return {
          price: price.price,
          subtraction: 1,
        };
      });
    }

    if (!editedDrink?._id) {
      fetch(`https://cash-registar-server.vercel.app/api/drink`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(editedDrink),
      })
        .then((res) => res.json())
        .then((data) => {
          fetchDrinks();
          setDrink({
            title: "",
            image: {},
            category: [],
            subCategory: [],
            stock: 0,
            unitSubtraction: "komad",
            prices: [],
            description: "",
          });
          setEditedDrink({
            title: "",
            image: {},
            category: [],
            subCategory: [],
            stock: 0,
            unitSubtraction: "komad",
            prices: [],
            description: "",
          });
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error adding drink:", error);
          setLoading(false);
        });
    } else {
      fetch(`https://cash-registar-server.vercel.app/api/drink/${drink._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(editedDrink),
      })
        .then((res) => res.json())
        .then((data) => {
          fetchDrinks();
          setDrink({
            title: "",
            image: {},
            category: [],
            subCategory: [],
            stock: 0,
            unitSubtraction: "komad",
            prices: [],
            description: "",
          });
          setEditedDrink({
            title: "",
            image: {},
            category: [],
            subCategory: [],
            stock: 0,
            unitSubtraction: "komad",
            prices: [],
            description: "",
          });
          setImage({});
          setNewPrice({ price: "", subtraction: "" });
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error updating drink:", error);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <form className="main-bar-edit" onSubmit={handleSubmit}>
        {" "}
        <div className={loading ? "loading active" : "loading "}>
          <div className="spinner"></div>
        </div>
        <div className="main-bar-edit-row">
          <div
            className="main-bar-edit-row-image"
            onClick={() => setUploadImageActive(true)}
          >
            {editedDrink?.image?.url ? (
              <>
                <img src={editedDrink?.image?.url} alt={editedDrink?.title} />
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={0.8}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>

                <span>Kliknite za dodavanje slike</span>
              </>
            )}
          </div>
          <div className="main-bar-edit-row-col">
            <div className="main-bar-edit-row-input">
              <label>Ime pića</label>
              <input
                type="text"
                value={editedDrink?.title}
                placeholder="Unesite ime pića"
                onChange={(e) =>
                  setEditedDrink({
                    ...editedDrink,
                    title: e.target.value,
                  })
                }
              />
            </div>{" "}
            <div className="main-bar-edit-row-input">
              {" "}
              <label> Jedinice oduzimanja</label>
              <div className="main-bar-edit-row-checkboxes">
                <div className="main-bar-edit-row-checkbox">
                  <input
                    type="radio"
                    name="unit"
                    _id={"kg"}
                    value={"kg"}
                    checked={editedDrink?.unitSubtraction === "kg"}
                    onChange={(e) =>
                      setEditedDrink({
                        ...editedDrink,
                        unitSubtraction: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="kg">kg</label>
                </div>
                <div className="main-bar-edit-row-checkbox">
                  <input
                    type="radio"
                    name="unit"
                    _id={"L"}
                    value={"L"}
                    checked={editedDrink?.unitSubtraction === "L"}
                    onChange={(e) =>
                      setEditedDrink({
                        ...editedDrink,
                        unitSubtraction: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="L">L</label>
                </div>
                <div className="main-bar-edit-row-checkbox">
                  <input
                    type="radio"
                    name="unit"
                    id="komad" // ID bi trebao biti konzistentan i obično se koristi za label 'for' atribut
                    value="komad"
                    checked={editedDrink?.unitSubtraction === "komad"}
                    onChange={(e) => {
                      const newUnitSubtraction = e.target.value;
                      let updatedPrices = [];

                      if (newUnitSubtraction === "komad") {
                        const firstPrice = editedDrink.prices?.[0]?.price || ""; // Uzmi prvu cijenu ili prazan string ako ne postoji
                        updatedPrices = [{ price: firstPrice, subtraction: 1 }];
                      }

                      setEditedDrink({
                        ...editedDrink,
                        unitSubtraction: newUnitSubtraction,
                        prices: updatedPrices,
                      });
                    }}
                  />
                  <label htmlFor="komad">komad</label>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="main-bar-edit-row">
          <div className="main-bar-edit-row-input">
            <label>Kategorija pića</label>
            <Select
              options={optionsCategories}
              styles={customSelectStyles}
              value={editedDrink?.category}
              // PLACEHOLDER
              placeholder="Odaberite kategoriju"
              isMulti={true}
              onChange={(selectedOptions) =>
                setEditedDrink({ ...editedDrink, category: selectedOptions })
              }
            />
          </div>
        </div>{" "}
        <div className="main-bar-edit-row">
          <div className="main-bar-edit-row-input">
            <label>Podkategorija pića</label>
            <Select
              options={optionsSubCategories}
              styles={customSelectStyles}
              value={editedDrink?.subCategory}
              // PLACEHOLDER
              placeholder="Podkategorije ovise o odabranoj kategoriji!"
              isMulti={true}
              onChange={(selectedOptions) => {
                setEditedDrink({
                  ...editedDrink,
                  subCategory: selectedOptions,
                });
              }}
            />
          </div>
        </div>
        <div className="main-bar-edit-row">
          <div className="main-bar-edit-row-input">
            <label>Stanje na zalihi</label>
            <input
              type="number"
              // number can be decimal
              step="any"
              value={editedDrink?.stock}
              placeholder="Unesite stanje na zalihi"
              onChange={(e) =>
                setEditedDrink({
                  ...editedDrink,
                  stock: e.target.value,
                })
              }
            />
            <div className="main-bar-edit-row-input-currency">
              {editedDrink?.unitSubtraction}
            </div>
          </div>
        </div>
        {
          // if the unitSubtraction is L or kg, show the prices
          editedDrink?.unitSubtraction === "komad" ? (
            <div className="main-bar-edit-row">
              <div className="main-bar-edit-row">
                <div className="main-bar-edit-row-input">
                  <label>Cijena</label>
                  <input
                    type="number"
                    step="any"
                    value={editedDrink?.prices[0]?.price}
                    placeholder="Unesite cijenu"
                    onChange={(e) =>
                      setEditedDrink({
                        ...editedDrink,
                        prices: [
                          {
                            price: e.target.value,
                            subtraction: editedDrink?.prices[0]?.subtraction,
                          },
                        ],
                      })
                    }
                  />
                  <div className="main-bar-edit-row-input-currency">€</div>
                </div>
              </div>{" "}
            </div>
          ) : (
            <>
              {" "}
              <div className="main-bar-edit-row">
                <div className="main-bar-edit-row-price-add">
                  <div className="main-bar-edit-row-input">
                    <label>Cijena</label>
                    <input
                      type="number"
                      step="any"
                      placeholder="Cijena..."
                      value={newPrice.price}
                      onChange={(e) =>
                        setNewPrice({ ...newPrice, price: e.target.value })
                      }
                    />
                    <div className="main-bar-edit-row-input-currency">€</div>
                  </div>
                  <div className="main-bar-edit-row-input">
                    <label>Količina</label>
                    <input
                      type="number"
                      placeholder="Kol..."
                      value={newPrice.subtraction}
                      step="any"
                      onChange={(e) =>
                        setNewPrice({
                          ...newPrice,
                          subtraction: e.target.value,
                        })
                      }
                    />
                    <div className="main-bar-edit-row-input-currency">
                      {editedDrink?.unitSubtraction}
                    </div>
                  </div>{" "}
                  <button
                    className="main-bar-edit-row-price-add-button"
                    onClick={() => {
                      setEditedDrink({
                        ...editedDrink,
                        prices: [...editedDrink.prices, newPrice],
                      });
                      setNewPrice({ price: "", subtraction: "" });
                    }}
                    type="button"
                  >
                    Dodaj
                  </button>
                </div>
              </div>
              <div className="main-bar-edit-row-prices-table">
                <div className="main-bar-edit-row-prices-table-header">
                  <div>
                    <span>Cijena</span>
                  </div>
                  <div>
                    <span>Količina</span>
                  </div>
                  <div></div>
                </div>
                <div className="main-bar-edit-row-prices-table-body">
                  {editedDrink?.prices?.map((price, index) => (
                    <div
                      key={index}
                      className="main-bar-edit-row-prices-table-row"
                    >
                      <div className="main-bar-edit-row-prices-table-row-input">
                        <input
                          type="number"
                          value={price.price}
                          onChange={(e) => {
                            const newPrices = [...editedDrink.prices];
                            newPrices[index].price = e.target.value;
                            setEditedDrink({
                              ...editedDrink,
                              prices: newPrices,
                            });
                          }}
                          min="0"
                          step="any"
                        />
                        <div className="main-bar-edit-row-input-currency">
                          €
                        </div>
                      </div>

                      <div className="main-bar-edit-row-prices-table-row-input">
                        <input
                          type="number"
                          step="any"
                          value={price.subtraction}
                          onChange={(e) => {
                            const newPrices = [...editedDrink.prices];
                            newPrices[index].subtraction = e.target.value;
                            setEditedDrink({
                              ...editedDrink,
                              prices: newPrices,
                            });
                          }}
                          min="0"
                        />
                        <div className="main-bar-edit-row-input-currency">
                          {editedDrink?.unitSubtraction}
                        </div>
                      </div>

                      <button
                        className="main-bar-edit-row-prices-table-row-button"
                        onClick={() => {
                          const newPrices = [...editedDrink.prices];
                          newPrices.splice(index, 1);
                          setEditedDrink({ ...editedDrink, prices: newPrices });
                        }}
                        type="button"
                      >
                        Obriši
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )
        }
        <div className="main-bar-edit-row-input">
          <label>Redni broj</label>
          <input
            type="number"
            value={editedDrink?.layoutNumber}
            placeholder="Unesite redni broj"
            onChange={(e) =>
              setEditedDrink({
                ...editedDrink,
                layoutNumber: e.target.value,
              })
            }
          />
        </div>
        <div className="main-bar-edit-row-input">
          <label>Opis pića</label>
          <textarea
            value={editedDrink?.description}
            placeholder="Upišite opis "
            onChange={(e) =>
              setEditedDrink({
                ...editedDrink,
                description: e.target.value,
              })
            }
          />
        </div>
        <div className="main-bar-edit-row bottom-buttons">
          <button
            className="main-bar-edit-row-button"
            type="button"
            onClick={() => {
              if (drink?._id) {
                deleteDrink();
              } else {
                setDrink({
                  title: "",
                  image: {},
                  category: [],
                  subCategory: [],
                  stock: 0,
                  unitSubtraction: "komad",
                  prices: [],
                  description: "",
                });
                setEditedDrink({
                  title: "",
                  image: {},
                  category: [],
                  subCategory: [],
                  stock: 0,
                  unitSubtraction: "komad",
                  prices: [],
                  description: "",
                });
                if (image?.url) {
                  deleteFile(image);
                }
                setImage({});
                setNewPrice({ price: "", subtraction: "" });
              }
            }}
          >
            {drink?._id ? "Obriši piće" : "Odustani"}
          </button>
          <button type="submit" className="main-bar-edit-row-button">
            {drink?._id ? "Spremi promjene" : "Dodaj piće"}
          </button>
        </div>
      </form>

      <ImageUpload
        image={image}
        setImage={setImage}
        category="drink"
        active={uploadImageActive}
        setActive={setUploadImageActive}
      />
      <AreYouSure
        x={areYouSure}
        setX={setAreYouSure}
        active={areYouSureActive}
        setActive={setAreYouSureActive}
        title={"Obriši piće: " + editedDrink?.title}
        message={"Jeste li sigurni da želite obrisati ovu piće?"}
      />
    </>
  );
};

export default EditDrink;
