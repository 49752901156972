import React, { useState, useEffect } from "react";
import ImageUpload from "./ImageUpload";
import { useAppContext } from "../context/AppContext";
import AreYouSure from "./AreYouSure";

const EditCategory = ({ setCategory, category }) => {
  const { setCategories } = useAppContext();
  const [editedCategory, setEditedCategory] = useState({
    title: "",
    shortDescription: "",
    image: {},
    layoutNumber: 0,
  });
  const [ImageUploadActive, setImageUploadActive] = useState(false);
  const [image, setImage] = useState();
  const [areYouSure, setAreYouSure] = useState("idle");
  const [areYouSureActive, setAreYouSureActive] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (category) {
      setEditedCategory({
        title: category.title || "",
        shortDescription: category.shortDescription || "",
        image: category.image || {},
        layoutNumber: category.layoutNumber || 0,
      });
    }
    console.log("category", category);
  }, [category]);

  useEffect(() => {
    if (image) {
      if (
        editedCategory?.image?.url &&
        editedCategory?.image?.url !== image?.url &&
        editedCategory?.image?.url !== category?.image?.url &&
        editedCategory?.image?.madeBy !== "admin"
      ) {
        deleteFile(editedCategory?.image);
      }
      setEditedCategory({ ...editedCategory, image: image });
    }
  }, [image]);

  const deleteFile = (file) => {
    const token = localStorage.getItem("token");
    fetch(`${file?.url}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    fetch(`https://cash-registar-server.vercel.app/api/files/${file._id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).catch((error) => {
      console.error("Error deleting file:", error);
    });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    e.preventDefault();

    console.log("editedCategory", editedCategory);

    if (!category?._id) {
      fetch(`https://cash-registar-server.vercel.app/api/category`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(editedCategory),
      })
        .then((res) => res.json())
        .then((data) => {
          fetchCategories();
          setEditedCategory({
            title: "",
            shortDescription: "",
            image: {},
            layoutNumber: 0,
          });
          setCategory({
            title: "",
            shortDescription: "",
            image: {},
            layoutNumber: 0,
          });

          setImage({});
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error adding category:", error);
          setLoading(false);
        });
    } else {
      fetch(
        `https://cash-registar-server.vercel.app/api/category/${category._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(editedCategory),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          fetchCategories();
          if (
            editedCategory?.image?.url &&
            editedCategory?.image?.url !== category?.image?.url &&
            editedCategory?.image?.madeBy !== "admin"
          ) {
            deleteFile(category?.image);
          }
          setCategory({
            title: "",
            shortDescription: "",
            image: {},
            layoutNumber: 0,
          });
          setEditedCategory({
            title: "",
            shortDescription: "",
            image: {},
            layoutNumber: 0,
          });

          setImage({});
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error updating category:", error);
          setLoading(false);
        });
    }
  };

  const fetchCategories = () => {
    const token = localStorage.getItem("token");
    fetch("https://cash-registar-server.vercel.app/api/category", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  useEffect(() => {
    if (areYouSure === "true" && category?._id) {
      performDeletion();
    }
    // Reset the areYouSure state after action is taken.
    setAreYouSure("idle");
  }, [areYouSure]);

  const deleteCategory = () => {
    // Trigger the AreYouSure component to show up.
    setAreYouSureActive(true);
  };

  const performDeletion = () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    if (category?.image?.madeBy !== "admin") {
      deleteFile(category?.image);
    }

    fetch(
      `https://cash-registar-server.vercel.app/api/category/${category._id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        fetchCategories();

        setCategory({
          title: "",
          shortDescription: "",
          image: {},
          layoutNumber: 0,
        });
        setEditedCategory({
          title: "",
          shortDescription: "",
          image: {},
          layoutNumber: 0,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting category:", error);
        setLoading(false);
      });
  };

  return (
    <>
      <form className="main-bar-edit" onSubmit={handleSubmit}>
        <div className={loading ? "loading active" : "loading "}>
          <div className="spinner"></div>
        </div>
        <div className="main-bar-edit-row">
          <div
            className="main-bar-edit-row-image"
            onClick={() => setImageUploadActive(true)}
          >
            {editedCategory?.image?.url ? (
              <>
                <img
                  src={editedCategory.image?.url}
                  alt={editedCategory?.title}
                />
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={0.8}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>

                <span>Kliknite za dodavanje slike</span>
              </>
            )}
          </div>
          <div className="main-bar-edit-row-col">
            <div className="main-bar-edit-row-input">
              <label>Ime kategorije</label>
              <input
                type="text"
                value={editedCategory?.title}
                placeholder="Unesite ime kategorije"
                onChange={(e) =>
                  setEditedCategory({
                    ...editedCategory,
                    title: e.target.value,
                  })
                }
              />
            </div>
            <div className="main-bar-edit-row-input">
              <label>Kratki opis kategorije</label>
              <input
                type="text"
                value={editedCategory?.shortDescription}
                onChange={(e) =>
                  setEditedCategory({
                    ...editedCategory,
                    shortDescription: e.target.value,
                  })
                }
                placeholder="Unesite kratki opis"
              />
            </div>
          </div>
        </div>
        <div className="main-bar-edit-row-input">
          <label>Redni broj</label>
          <input
            type="text"
            value={editedCategory?.layoutNumber}
            onChange={(e) =>
              setEditedCategory({
                ...editedCategory,
                layoutNumber: e.target.value,
              })
            }
            placeholder="Unesite redni broj"
          />
        </div>
        <div className="main-bar-edit-row bottom-buttons">
          <button
            className="main-bar-edit-row-button"
            type="button"
            onClick={() => {
              if (category?._id) {
                deleteCategory();
              } else {
                setCategory({
                  title: "",
                  shortDescription: "",
                  image: {},
                  layoutNumber: 0,
                });
                setEditedCategory({
                  title: "",
                  shortDescription: "",
                  image: {},
                  layoutNumber: 0,
                });

                if (image?.url) {
                  deleteFile(image);
                }

                setImage({});
              }
            }}
          >
            {category?._id ? "Obriši kategoriju" : "Odustani"}
          </button>
          <button type="submit" className="main-bar-edit-row-button">
            Spremi promjene
          </button>
        </div>
      </form>
      <ImageUpload
        image={image}
        setImage={setImage}
        category={"category"}
        active={ImageUploadActive}
        setActive={setImageUploadActive}
      />
      <AreYouSure
        x={areYouSure}
        setX={setAreYouSure}
        active={areYouSureActive}
        setActive={setAreYouSureActive}
        title={"Obriši kategoriju"}
        message={"Jeste li sigurni da želite obrisati ovu kategoriju?"}
      />
    </>
  );
};

export default EditCategory;
