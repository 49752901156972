import React, { useState, useEffect } from "react";
import Select from "react-select";
import AreYouSure from "./AreYouSure";
import ImageUpload from "./ImageUpload";
import { useAppContext } from "../context/AppContext";

const EditSubCategory = ({ subCategory, categories, setSubCategory }) => {
  const { setSubCategories } = useAppContext();
  const [editedSubCategory, setEditedSubCategory] = useState({
    title: "",
    shortDescription: "",
    image: {},
    category: [],
    layoutNumber: 0,
  });
  const [ImageUploadActive, setImageUploadActive] = useState(false);
  const [image, setImage] = useState();
  const [areYouSure, setAreYouSure] = useState("idle");
  const [areYouSureActive, setAreYouSureActive] = useState(false);
  const [categoryOption, setCategoryOption] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (categories) {
      const options = categories.map((category) => {
        return {
          value: category._id,
          label: category.title,
        };
      });
      setCategoryOption(options);

      if (subCategory) {
        setEditedSubCategory({
          title: subCategory?.title || "",
          shortDescription: subCategory?.shortDescription || "",
          image: subCategory?.image || {},
          category: subCategory?.category || [],
          layoutNumber: subCategory?.layoutNumber || 0,
        });
      }
    }

    console.log("subCategory", subCategory);
    console.log("editedSubCategory", editedSubCategory);
  }, [categories, subCategory]);

  useEffect(() => {
    setEditedSubCategory({
      title: subCategory?.title || "",
      shortDescription: subCategory?.shortDescription || "",
      image: subCategory?.image || {},
      category: getCategoryOptions(subCategory?.category) || [],
      layoutNumber: subCategory?.layoutNumber || 0,
    });
  }, [subCategory]);

  const getCategoryOptions = (category) => {
    if (!category || categories.length === 0 || category.length === 0)
      return [];
    const options = category?.map((c) => {
      return {
        value: c,
        label: categories.find((cat) => cat._id === c)?.title,
      };
    });
    return options;
  };

  const getValuesFromOptions = (options) => {
    const values = options.map((option) => {
      return option.value;
    });
    return values;
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      borderColor: state.isFocused ? "#D1D5DB" : "gray", // Adjust the colors as needed
      borderRadius: 0,
      borderWidth: "0 0 1px 0",
      boxShadow: "none", // Removes the default shadow
      "&:hover": {
        borderColor: state.isFocused ? "#D1D5DB" : "gray",
      },
      color: "white",
      borderRight: "none",
    }),

    menu: (provided) => ({
      ...provided,
      backgroundColor: "white", // Adjust if you want a different background for the dropdown
      color: "white",
      borderRight: "none",

      //remove the padding
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#000" : "white",
      color: state.isSelected ? "white" : "black",
      cursor: "pointer",
      fontSize: "0.875rem",
      margin: 0,

      "&:hover": {
        backgroundColor: "#eee",
      },
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "#a0a0a0", // Placeholder color, adjust as needed
      fontSize: "0.875rem",
    }),
    input: (provided) => ({
      ...provided,
      color: "white", // Input text color
    }),
    // Add other customizations as needed

    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#2b2b2b",
      };
    },

    //remove the vertical line between the multi values
    multiValueRemove: (styles) => {
      return {
        ...styles,
        width: "1.3rem",
        color: "white",
        strokWidth: "0.8",
        height: "1.3rem",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "black",
          color: "white",
        },
      };
    },

    //;pwer the opacity of the vertical line between values and dropdown indicator
    indicatorSeparator: (styles) => {
      return {
        ...styles,
        backgroundColor: "rgba(255, 255, 255, 0.24)",
      };
    },

    //style the multi value label
    multiValueLabel: (styles) => {
      return {
        ...styles,
        color: "white",
        fontSize: "0.75rem",
      };
    },
  };

  useEffect(() => {
    if (image) {
      if (
        editedSubCategory?.image?.url &&
        editedSubCategory?.image?.url !== image?.url &&
        editedSubCategory?.image?.url !== subCategory?.image?.url &&
        editedSubCategory?.image?.madeBy !== "admin"
      ) {
        deleteFile(editedSubCategory?.image);
      }
      setEditedSubCategory({ ...editedSubCategory, image: image });
    }
  }, [image]);

  const handleSubmit = (e) => {
    setLoading(true);

    const token = localStorage.getItem("token");
    e.preventDefault();

    if (
      editedSubCategory?.category?.length > 0 &&
      editedSubCategory?.category[0]?.value
    ) {
      editedSubCategory.category = getValuesFromOptions(
        editedSubCategory.category
      );
      setEditedSubCategory({
        ...editedSubCategory,
        category: editedSubCategory.category,
      });
    }

    if (!subCategory?._id) {
      fetch(`https://cash-registar-server.vercel.app/api/subCategory`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(editedSubCategory),
      })
        .then((res) => res.json())
        .then((data) => {
          fetchSubCategories();
          setEditedSubCategory({
            title: "",
            shortDescription: "",
            image: {},
            category: [],
            layoutNumber: 0,
          });
          setImage({});
          setSubCategory({
            title: "",
            shortDescription: "",
            image: {},
            category: [],
            layoutNumber: 0,
          });

          setLoading(false);
        })
        .catch((error) => {
          console.error("Error adding subCategory:", error);
          setLoading(false);
        });
    } else {
      fetch(
        `https://cash-registar-server.vercel.app/api/subCategory/${subCategory._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(editedSubCategory),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          fetchSubCategories();
          if (
            editedSubCategory?.image?.url &&
            editedSubCategory?.image?.url !== subCategory?.image?.url &&
            editedSubCategory?.image?.madeBy !== "admin"
          ) {
            deleteFile(subCategory?.image);
          }
          setSubCategory({
            title: "",
            shortDescription: "",
            image: {},
            category: [],
            layoutNumber: 0,
          });
          setEditedSubCategory({
            title: "",
            shortDescription: "",
            image: {},
            category: [],
            layoutNumber: 0,
          });
          setImage({});
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error updating subCategory:", error);
          setLoading(false);
        });
    }
  };

  const deleteFile = (file) => {
    const token = localStorage.getItem("token");
    fetch(`https://cash-registar-server.vercel.app/api/files/${file._id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    fetch(`${file.url}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const fetchSubCategories = () => {
    const token = localStorage.getItem("token");
    fetch("https://cash-registar-server.vercel.app/api/subCategory", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSubCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  const deleteSubCategory = () => {
    // Trigger the AreYouSure component to show up.
    setAreYouSureActive(true);
  };

  const performDeletion = () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    if (subCategory?.image?.madeBy !== "admin") {
      deleteFile(subCategory?.image);
    }

    fetch(
      `https://cash-registar-server.vercel.app/api/subCategory/${subCategory._id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        fetchSubCategories();
        setSubCategory({
          title: "",
          shortDescription: "",
          image: {},
          category: [],
          layoutNumber: 0,
        });
        setEditedSubCategory({
          title: "",
          shortDescription: "",
          image: {},
          category: [],
          layoutNumber: 0,
        });
        setImage({});
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting subCategory:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (areYouSure === "true" && subCategory?._id) {
      performDeletion();
    }
    // Reset the areYouSure state after action is taken.
    setAreYouSure("idle");
  }, [areYouSure]);

  return (
    <>
      <form className="main-bar-edit" onSubmit={handleSubmit}>
        {" "}
        <div className={loading ? "loading active" : "loading "}>
          <div className="spinner"></div>
        </div>
        <div className="main-bar-edit-row">
          <div
            className="main-bar-edit-row-image"
            onClick={() => setImageUploadActive(true)}
          >
            {editedSubCategory?.image?.url ? (
              <img
                src={editedSubCategory?.image?.url}
                alt={editedSubCategory?.title}
              />
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={0.8}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>

                <span>Kliknite za dodavanje slike</span>
              </>
            )}
          </div>
          <div className="main-bar-edit-row-col">
            <div className="main-bar-edit-row-input">
              <label>Ime podkategorije</label>
              <input
                type="text"
                value={editedSubCategory?.title}
                placeholder="Unesite ime "
                onChange={(e) =>
                  setEditedSubCategory({
                    ...editedSubCategory,
                    title: e.target.value,
                  })
                }
              />
            </div>
            <div className="main-bar-edit-row-input">
              <label>Kratki opis podkategorije</label>
              <input
                type="text"
                value={editedSubCategory?.shortDescription}
                placeholder="Unesite kratki opis"
                onChange={(e) =>
                  setEditedSubCategory({
                    ...editedSubCategory,
                    shortDescription: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="main-bar-edit-row">
          <div className="main-bar-edit-row-input">
            <label>Izaberite kategorije</label>
            <Select
              styles={customSelectStyles}
              placeholder="Odaberite kategoriju"
              isMulti={true}
              options={categoryOption}
              value={editedSubCategory?.category}
              onChange={(e) =>
                setEditedSubCategory({
                  ...editedSubCategory,
                  category: e,
                })
              }
            />
          </div>
        </div>{" "}
        <div className="main-bar-edit-row">
          <div className="main-bar-edit-row-input">
            <label>Redni broj</label>
            <input
              type="number"
              value={editedSubCategory?.layoutNumber}
              placeholder="Unesite redni broj"
              onChange={(e) =>
                setEditedSubCategory({
                  ...editedSubCategory,
                  layoutNumber: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="main-bar-edit-row bottom-buttons">
          <button
            className="main-bar-edit-row-button"
            onClick={() => {
              if (subCategory?._id) {
                deleteSubCategory();
              } else {
                setSubCategory({
                  title: "",
                  shortDescription: "",
                  image: {},
                  category: [],
                  layoutNumber: 0,
                });
                setEditedSubCategory({
                  title: "",
                  shortDescription: "",
                  image: {},
                  category: [],
                  layoutNumber: 0,
                });

                if (image?.url) {
                  deleteFile(image);
                }

                setImage({});
              }
            }}
            type="button"
          >
            {subCategory?._id ? "Obriši podkategoriju" : "Odustani"}
          </button>
          <button type="submit" className="main-bar-edit-row-button">
            Spremi promjene
          </button>
        </div>
      </form>
      <ImageUpload
        image={image}
        setImage={setImage}
        category={"category"}
        active={ImageUploadActive}
        setActive={setImageUploadActive}
      />
      <AreYouSure
        x={areYouSure}
        setX={setAreYouSure}
        active={areYouSureActive}
        setActive={setAreYouSureActive}
        title={"Obriši podkategoriju"}
        message={"Jeste li sigurni da želite obrisati ovu podkategoriju?"}
      />
    </>
  );
};

export default EditSubCategory;
