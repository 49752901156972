import DvijeStoliceSepareSVG from "../assets/images/tables-icons/2-stole-separe.svg";
import DvijeStoliceKaucSVG from "../assets/images/tables-icons/2-stolice-kauč-2.svg";
import DvijeStoliceObican45StupnjevaSVG from "../assets/images/tables-icons/2-stolice-običan-45-stupnjeva.svg";
import DvijeStoliceObicanSVG from "../assets/images/tables-icons/2-stolice-običan.svg";
import TriStoliceObican45StupnjevaSVG from "../assets/images/tables-icons/3-stolice-običan-45-stupnjeva.svg";
import TriStoliceObicanSVG from "../assets/images/tables-icons/3-stolice-običan.svg";
import CetriStoliceObicanSVG from "../assets/images/tables-icons/4-stolice-običan.svg";
import CetriStoliceObican45StupnjevaSVG from "../assets/images/tables-icons/4-stolice-običan-45-stupnjeva.svg";
import muskiWC from "../assets/images/tables-icons/muški-wc-2-1.svg";
import zenskiWC from "../assets/images/tables-icons/ženski-wc-2-1.svg";
import sank from "../assets/images/tables-icons/šank-1-7.svg";
import separe from "../assets/images/tables-icons/separe-10x1.svg";
import televizor from "../assets/images/tables-icons/televizor.svg";
import televizor45Stupnjeva from "../assets/images/tables-icons/televizor-45-stupnjeva.svg";
import DvijeStoliceKaucDvaSVG from "../assets/images/tables-icons/2-stolice-kauč-2.svg";

const serverURL = "https://caffe-bar-comodo-server.lukakordic.me";

const getStaticImageFromKey = (key) => {
  switch (key) {
    case "2-stole-separe.svg":
      return DvijeStoliceSepareSVG;
    case "2-stolice-kauc-2.svg":
      return DvijeStoliceKaucSVG;
    case "2-stolice-običan-45-stupnjeva.svg":
      return DvijeStoliceObican45StupnjevaSVG;
    case "2-stolice-običan.svg":
      return DvijeStoliceObicanSVG;
    case "3-stolice-običan-45-stupnjeva.svg":
      return TriStoliceObican45StupnjevaSVG;
    case "4-stolice-običan.svg":
      return CetriStoliceObicanSVG;
    case "4-stolice-običan-45-stupnjeva.svg":
      return CetriStoliceObican45StupnjevaSVG;
    case "muški-wc-2-1.svg":
      return muskiWC;
    case "ženski-wc-2-1.svg":
      return zenskiWC;
    case "šank-1-7.svg":
      return sank;
    case "separe-10x1.svg":
      return separe;
    case "televizor.svg":
      return televizor;
    case "televizor-45-stupnjeva.svg":
      return televizor45Stupnjeva;
    case "3-stolice-običan.svg":
      return TriStoliceObicanSVG;
    case "2-stolice-kauč-2.svg":
      return DvijeStoliceKaucDvaSVG;
    default:
      console.error("No image found for key: ", key);
      return DvijeStoliceSepareSVG;
  }
};

export { serverURL, getStaticImageFromKey };
