import React from "react";
import "../styles/BottomMenuBar.scss";

const BottomMenuBar = () => {
  return (
    <div className="bottom-menu-bar">
      <div className="bottom-menu-bar-left">
        <div className="bottom-menu-bar-left-item">
          <p>
            Vrijeme <span> 12:00h</span>
          </p>
        </div>
        <div className="bottom-menu-bar-left-item">
          <p>
            Total: <span>1100.00 €</span>
          </p>
        </div>
        <div className="bottom-menu-bar-left-item">
          <p>
            Broj kava danas <span> 150</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BottomMenuBar;
