// AppContext.js
import React, { createContext, useContext, useState } from "react";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [drinks, setDrinks] = useState([
    /*
    {
      _id: "6491d2534212b1b2b403eb11",
      title: "Coca Cola",
      category: ["6491d2534212b1b2b403eb4c", "6491d2534218a1b2b403eb4c"],
      prices: [
        {
          price: 2.5,
          subtraction: 1,
        },
        {
          price: 2.7,
          subtraction: 1,
        },
      ],
      unitSubtraction: "komad",
      stock: 100,
      description:
        "Coca Cola je gazirano bezalkoholno piće koje se proizvodi od strane kompanije Coca-Cola Company. Coca Cola je najprodavanije bezalkoholno piće u svetu.",
      image:
        "https://assets.turbologo.com/blog/en/2019/08/19085106/coca-cola-logo-2007.jpg",
    },
    {
      _id: "6491d2534212b1b2b403eb12",
      title: "Coca Cola Zero",
      category: ["6491d2534212b1b2b403eb4c", "6491d2534218a1b2b403eb4c"],
      stock: 100,
      unitSubtraction: "komad",
      prices: [
        {
          price: 2.5,
          subtraction: 1,
        },
      ],
      description:
        "Coca Cola Zero je bezalkoholno gazirano piće koje se proizvodi od strane kompanije Coca-Cola Company. Coca Cola Zero je bez šećera.",
      image:
        "https://res.cloudinary.com/dvg2o5azu/image/upload/v1708010560/cola_zero_qs3okx.webp",
    },
    {
      _id: "6491d2534212b1b2b403eb13",
      title: "Cedevita",
      category: ["6491d2534212b1b2b403eb4c", "6491d2534218a1b2b403eb4c"],
      stock: 100,
      unitSubtraction: "komad",
      prices: [
        {
          price: 2.5,
          subtraction: 1,
        },
      ],
      description:
        "Cedevita je instant napitak koji se koristi za pripremu osvežavajućeg napitka. Cedevita je proizvod kompanije Atlantic Grupa.",
      image:
        "https://res.cloudinary.com/dvg2o5azu/image/upload/v1708010560/cedevita_lkl5ap.jpg",
    },
    {
      _id: "6491d2534212b1b2b403eb14",
      title: "Limonada",
      category: ["6491d2534212b1b2b403eb4c", "6491d2534218a1b2b403eb4c"],
      stock: 100,
      unitSubtraction: "komad",
      prices: [
        {
          price: 2.5,
          subtraction: 1,
        },
      ],
      description:
        "Limonada je osvežavajuće gazirano bezalkoholno piće koje se pravi od limuna, vode i šećera.",
      image:
        "https://res.cloudinary.com/dvg2o5azu/image/upload/v1708010561/limunada_vlkuhv.jpg",
    },
    {
      _id: "6491d2534212b1b2b403eb15",
      title: "Naranča",
      category: ["6491d2534212b1b2b403eb4c", "6491d2534218a1b2b403eb4c"],
      stock: 100,
      unitSubtraction: "komad",
      prices: [
        {
          price: 2.5,
          subtraction: 1,
        },
      ],
      description:
        "Naranča je osvežavajuće gazirano bezalkoholno piće koje se pravi od naranče, vode i šećera.",
      image:
        "https://res.cloudinary.com/dvg2o5azu/image/upload/v1708010561/naranca_ro3efd.png",
    },
    {
      _id: "6491d2534212b1b2b403eb16",
      title: "Pago",
      category: ["6491d2534212b1b2b403eb4c", "6491d2534218a1b2b403eb4c"],
      stock: 100,
      unitSubtraction: "komad",
      prices: [
        {
          price: 2.5,
          subtraction: 1,
        },
      ],
      description:
        "Pago je osvežavajuće gazirano bezalkoholno piće koje se pravi od voća, vode i šećera.",
      image:
        "https://res.cloudinary.com/dvg2o5azu/image/upload/v1708010561/pago_logo_glow_umnptq.png",
    },

    {
      _id: "6491d2534212b1b2b403eb21",
      title: "Tangerine Juice",
      category: ["6491d2534212b1b2b403eb4c", "6491d2534218a1b2b403eb4c"],
      stock: 100,
      unitSubtraction: "komad",
      prices: [
        {
          price: 2.5,
          subtraction: 1,
        },
      ],
      description:
        "Tangerine Juice is a refreshing non-alcoholic drink made from tangerine, water and sugar.",
      image:
        "https://res.cloudinary.com/dvg2o5azu/image/upload/v1708010562/tangerine_wijc2n.jpg",
    },
    {
      _id: "6491d2534212b1b2b403eb22",
      title: "Thomas Henry",
      category: ["6491d2534212b1b2b403eb4c", "6491d2534218a1b2b403eb4c"],
      stock: 100,
      unitSubtraction: "komad",
      prices: [
        {
          price: 2.5,
          subtraction: 1,
        },
      ],
      description:
        "Thomas Henry is a refreshing non-alcoholic drink made from tangerine, water and sugar.",
      image:
        "https://res.cloudinary.com/dvg2o5azu/image/upload/v1708010561/thoma_henry_yb6mhv.webp",
    },

    {
      _id: "6491d2534212b1b2b403eb24",
      title: "Pago",
      category: ["6491d2534212b1b2b403eb4c", "6491d2534218a1b2b403eb4c"],
      stock: 100,
      unitSubtraction: "komad",
      prices: [
        {
          price: 2.3,
          subtraction: 1,
        },
      ],
      description:
        "Pago is a refreshing non-alcoholic drink made from fruit, water and sugar.",
      image:
        "https://res.cloudinary.com/dvg2o5azu/image/upload/v1708010561/pago_logo_glow_umnptq.png",
    },
    {
      _id: "6491d2534212b1b2b403eb50",
      title: "Espresso",
      category: ["6491d2534212b1b2b403eb4c", "6491d2534212a1b2b403eb4c"],
      stock: 2,
      unitSubtraction: "komad",
      prices: [
        {
          price: 1.5,
          subtraction: 1,
        },
      ],
      description:
        "Espresso is a concentrated form of coffee served in small, strong shots. It's known for its rich flavor and creamy texture, often forming a velvety foam on top known as crema. A perfect espresso is the foundation of many coffee drinks and is revered for its energy-boosting properties and deep, complex flavor profile.",
      image:
        "https://images.getrecipekit.com/20230414194230-espresso-cup-with-coffee-beans.jpg?aspect_ratio=16:9&quality=90&",
    },
    {
      _id: "6491d2534212b1b2b403eb2aa",
      title: "Pelinkovac",
      category: ["6491d2534212b1b2b403eb4c", "6491d2534e18a1b2b404eb4c"],
      stock: 2,
      unitSubtraction: "L",
      prices: [
        {
          price: 1.5,
          subtraction: 0.03,
        },
        {
          price: 2.4,
          subtraction: 0.05,
        },
      ],
      description:
        "Pelinkovac is a bitter liqueur based on wormwood, popular in Croatia, Serbia, and Bosnia and Herzegovina. It is often drunk with a slice of lemon. It is also used as a herbal medicine.",
      image:
        "https://i0.wp.com/shop.stridon.hr/wp-content/uploads/2020/04/Liker-Pelinkovac-010L-Badel-1862-02.jpg?fit=1200%2C1200&ssl=1",
    },
  */
  ]);

  const [categories, setCategories] = useState([
    /*
    {
      title: "Sva pića",
      image:
        "https://images-katalozi.njuskalo.hr/data/image/500x705/42377/set-staklenih-casa-za-razna-pica-4-dijelni-interspar1638967161513-interspar-211884835.jpg",
      shortDescription: "Piva, Žestoka pića, Vina, Kokteli...",
      _id: "6491d2534212b1b2b403eb4c",
    },
    {
      title: "Topli napitci",
      image:
        "https://www.poslovni.hr/wp-content/uploads/2022/12/louis-hansel-vD9gZXbmRP4-unsplash.jpg",
      shortDescription: "Espresso, Macchiato, Cappuccino, Latte...",
      _id: "6491d2534212a1b2b403eb4c",
    },

    {
      title: "Sokovi",
      image:
        "https://www.arz.hr/wp-content/uploads/2022/04/prirodni-sokovi--kako-ih-napraviti-scaled.jpg",
      shortDescription: "Naranča, Jabuka, Ananas, Borovnica...",
      _id: "6491d2534218a1b2b403eb4c",
    },

    {
      title: "Piva",
      image: "https://possector.hr/wp-content/uploads/2023/05/vrste-piva.webp",
      shortDescription: "Lager, Ale, Stout, IPA...",
      _id: "6491d2534e18a1b2b403eb4c",
    },

    {
      title: "Žestoka pića",
      image:
        "https://www.teklic.hr/slike/2021/01/pexels-oleg-magni-1005642.jpg",
      shortDescription: "Votka, Tekila, Rum, Whiskey...",
      _id: "6491d2534e18a1b2b404eb4c",
    },
    {
      title: "Vina",
      image: "https://glasistrenovine.hr/fotografije/255218-.jpg",
      shortDescription: "Bijela, Crvena, Rose, Desertna...",
      _id: "6491f2234e18a1b2b404eb4c",
    },
    {
      title: "Kokteli",
      image: "https://hrb.com.hr/wp-content/uploads/2023/06/kokteli-1.jpg",
      shortDescription: "Cuba Libre, Mojito, Margarita, Gin Tonic...",
      _id: "6491f2534e18a1b2b404eb4c",
    },
  */
  ]);
  const [subCategories, setSubCategories] = useState([]);
  const [drinkCombinations, setDrinkCombinations] = useState([
    /*
    {
      _id: "6491f2534e18a1b2b404ee4c",
      title: "Esspreso + Coca Cola",
      image:
        "https://jolie.hr/wp-content/uploads/2018/10/22070876_136958156934105_6823423266233778176_n-696x696.jpg",
      category: ["6491d2534212b1b2b403eb4c", "6491d2534e18a1b2b404eb4c"],
      subCategory: [],
      drinks: [
        {
          drink: "6491d2534212b1b2b403eb11",
          subtraction: 1,
        },
        {
          drink: "6491d2534212b1b2b403eb50",
          subtraction: 1,
        },
      ],
      price: 3.5,
      description:
        "Kava + Coca Cola je osvežavajući napitak koji se sastoji od kave i Coca Cole.",
    },
    */
  ]);

  const [tables, setTables] = useState([
    /*
    // KOCKA
    {
      _id: "6491d2534212b1b2b403eb52",
      title: "UDK-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan-45-stupnjeva_ofe09z.svg",
      },
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "6491d2gb4212b1b2b403eb52",
      rotate: "0",
      titleFontSize: ".7rem",
      isTable: true,
      titleTop: "-35",
      titleLeft: "30",
      zIndex: 1,
    },
    {
      _id: "6451s2534212b1b2b403eb52",
      title: "UDK-2",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan-45-stupnjeva_ofe09z.svg",
      },
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "6491d2gb4212b1b2b403eb52",
      rotate: "0",
      titleFontSize: ".7rem",
      isTable: true,
      titleTop: "-35",
      titleLeft: "30",
      zIndex: 1,
    },
    {
      _id: "6491dv53421vb1b2b403eb52",
      title: "UDK-3",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/2-stolice-obi%C4%8Dan_eazduk.svg",
      },
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "6491d2gb4212b1b2b403eb52",
      rotate: "90",
      titleFontSize: ".7rem",
      isTable: true,
      titleTop: "-37",
      titleLeft: "30",
      zIndex: 1,
    },
    {
      _id: "6491d253421gb1bcb403eb52",
      title: "UDK-4",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan-45-stupnjeva_ofe09z.svg",
      },
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "6491d2gb4212b1b2b403eb52",
      rotate: "0",
      titleFontSize: ".7rem",
      isTable: true,
      titleTop: "-35",
      titleLeft: "30",
      zIndex: 1,
    },
    {
      _id: "6491d253421xb1bnkh03eb52",
      title: "UDK-5",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan-45-stupnjeva_ofe09z.svg",
      },
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "6491d2gb4212b1b2b403eb52",
      rotate: "0",
      titleFontSize: ".7rem",
      isTable: true,
      titleTop: "-35",
      titleLeft: "30",
      zIndex: 1,
    },
    {
      _id: "65df5c8d3c0bc9000e0lm182",
      title: "UDK2-televizor",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202645/cash-registar-icons/televizor-45-stupnjeva_nxxjxj.svg",
      },
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8d186e2b000e73a9f9",
      rotate: "0",
      isTable: false,
      titleFontSize: ".7rem",
      titleTop: "-35",
      titleLeft: "30",
      zIndex: 1,
    },

    // KOCKA 2
    {
      _id: "65df5c8d3c0bc9000e045182",
      title: "UDK2-televizor",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202645/cash-registar-icons/televizor-45-stupnjeva_nxxjxj.svg",
      },
      isTable: false,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8d186e2b000e73a9f9",
      rotate: "90",
      titleFontSize: ".7rem",
      titleTop: "-35",
      titleLeft: "30",
      zIndex: 1,
    },
    {
      _id: "65df5c8d3c0bc9000e086182",
      title: "UDK2-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8d186e2b000e73a9f9",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-35",
      titleLeft: "30",
      zIndex: 1,
    },
    {
      _id: "65df5c8dd64dc6000ec29365",
      title: "UDK2-2",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8d186e2b000e73a9f9",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-25",
      titleLeft: "30",
      zIndex: 1,
    },

    // GLAVNI DIO
    {
      _id: "65df5c8d9496cd000e2f9529",
      title: "UDGD-muški-wc",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202644/cash-registar-icons/mu%C5%A1ki-wc-2-1_zfpkv6.svg",
      },
      isTable: false,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8dd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-35",
      titleLeft: "30",
      zIndex: 1,
    },
    {
      _id: "65df5c8d186e2b000e71a9f9",
      title: "UDGD-ženski-wc",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202645/cash-registar-icons/%C5%BEenski-wc-2-1_ykozi3.svg",
      },
      isTable: false,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8dd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-35",
      titleLeft: "30",
      zIndex: 1,
    },
    {
      _id: "65df5c8d186e2r000e73a9f9",
      title: "UDGD-šank",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202644/cash-registar-icons/%C5%A1ank-1-7_bshsbk.svg",
      },
      isTable: false,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8dd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-35",
      titleLeft: "30",
      zIndex: 1,
    },

    {
      _id: "65df5c8d186e2x000e73a9f9",
      title: "UDGD-kauč-1-1",
      order: [],
      isTable: true,
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709208877/cash-registar-icons/2-stolice-kau%C4%8D-2_nojlwt.svg",
      },
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8dd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "30",
      titleLeft: "-80",
      zIndex: 1,
    },
    {
      _id: "65df5c8d964d72000ece615b",
      title: "UDGD-kauč-1-2",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709208877/cash-registar-icons/2-stolice-kau%C4%8D-2_nojlwt.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8dd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "30",
      titleLeft: "-80",
      zIndex: 1,
    },

    {
      _id: "65df5c8d7d6a2f000ec0eba7",
      title: "UDGD-kauč-2-2",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709208877/cash-registar-icons/2-stolice-kau%C4%8D-2_nojlwt.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8dd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "30",
      titleLeft: "-80",
      zIndex: 1,
    },
    {
      _id: "65df5c8d3fb9d8000e6d97ac",
      title: "UDGD-kauč-2-2",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709208877/cash-registar-icons/2-stolice-kau%C4%8D-2_nojlwt.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8dd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "30",
      titleLeft: "-80",
      zIndex: 1,
    },
    {
      _id: "34df5c8d3fb9d8000e6d97ac",
      title: "UDGD-4-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8dd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "30",
      titleLeft: "-80",
      zIndex: 1,
    },
    {
      _id: "24df5c8OIfbhd8000e6d97ac",
      title: "UDGD-3-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/3-stolice-obi%C4%8Dan_u3yohs.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8dd386ce000e51f3d5",
      rotate: "180",
      titleFontSize: ".7rem",
      titleTop: "-35",
      titleLeft: "30",
      zIndex: 1,
    },
    {
      _id: "24df5c8d3fbhd8000SDd97ac",
      title: "UDGD-3-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/3-stolice-obi%C4%8Dan_u3yohs.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8dd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-35",
      titleLeft: "30",
      zIndex: 1,
    },
    {
      _id: "24df5c8d3fbhdklp0SDd97ac",
      title: "UDGD-3-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/3-stolice-obi%C4%8Dan_u3yohs.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8dd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-35",
      titleLeft: "30",
      zIndex: 1,
    },
    {
      _id: "24df5c8d3fbhdklp0Sfg97ac",
      title: "UDGD-3-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/2-stolice-obi%C4%8Dan_eazduk.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5c8dd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "40",
      titleLeft: "-120",
      zIndex: 1,
    },

    // TRIBINA
    {
      _id: "65df5c8d4dd95e000e8b54xc",
      title: "UDK2-televizor",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202645/cash-registar-icons/televizor_i0ppaq.svg",
      },
      isTable: false,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjkd386ce000e51f3d5",
      rotate: "90",
      titleFontSize: ".7rem",
      titleTop: "-25",
      titleLeft: "0",
      zIndex: 1,
    },
    {
      _id: "65df5c8d4ddVBe000e8b54xc",
      title: "UDT-K-1-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709219796/2-stole-separe_glrgqc.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjkd386ce000e51f3d5",
      rotate: "90",
      titleFontSize: ".6rem",
      titleTop: "-25",
      titleLeft: "0",
      zIndex: 1,
    },
    {
      _id: "65df5c8d6f06fjk00e423dxy",
      title: "UDT-K-1-2",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709219796/2-stole-separe_glrgqc.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjkd386ce000e51f3d5",
      rotate: "90",
      titleFontSize: ".6rem",
      titleTop: "-25",
      titleLeft: "0",
      zIndex: 1,
    },
    {
      _id: "65df5c8jkdd95e000e8b54xc",
      title: "UDT-K-1-3",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709219796/2-stole-separe_glrgqc.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjkd386ce000e51f3d5",
      rotate: "90",
      titleFontSize: ".6rem",
      titleTop: "-25",
      titleLeft: "0",
      zIndex: 1,
    },
    {
      _id: "65df5c8d6f06flo00e412dxy",
      title: "UDT-K-1-4",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709219796/2-stole-separe_glrgqc.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjkd386ce000e51f3d5",
      rotate: "90",
      titleFontSize: ".6rem",
      titleTop: "-25",
      zIndex: 1,

      titleLeft: "0",
    },
    {
      _id: "65df5c8jkdd95e000g8bdfxc",
      title: "UDT-K-1-5",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709219796/2-stole-separe_glrgqc.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjkd386ce000e51f3d5",
      rotate: "90",
      titleFontSize: ".6rem",
      titleTop: "-25",
      titleLeft: "0",
      zIndex: 1,
    },
    {
      _id: "65df5c8d6f06fjk00e242dxy",
      title: "UDT-K-1-6",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709219796/2-stole-separe_glrgqc.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjkd386ce000e51f3d5",
      rotate: "90",
      titleFontSize: ".6rem",
      titleTop: "-25",
      titleLeft: "0",
      zIndex: 1,
    },
    {
      _id: "65df5c8jkdd95e000e8bdfxc",
      title: "UDT-S-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/2-stolice-obi%C4%8Dan_eazduk.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjkd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-5",
      titleLeft: "20",
      zIndex: 1,
    },
    {
      _id: "65df5c8d6f06fjc00e442dxy",
      title: "UDT-S-2",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/2-stolice-obi%C4%8Dan_eazduk.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjkd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-5",
      titleLeft: "20",
      zIndex: 1,
    },
    {
      _id: "65df5c8jfdd95e000e8bdfxc",
      title: "UDT-S-3",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/2-stolice-obi%C4%8Dan_eazduk.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjkd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-5",
      titleLeft: "20",
      zIndex: 1,
    },
    {
      _id: "65df5c8d6f06fjk00e442dxy",
      title: "UDT-S-4",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/2-stolice-obi%C4%8Dan_eazduk.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjkd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-5",
      titleLeft: "20",
      zIndex: 1,
    },
    {
      _id: "65df5c8d6f06mns00e442dxy",
      title: "UDT-SEPARE",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709220571/separe-10x1_rorzuf.svg",
      },
      isTable: false,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjkd386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-5",
      titleLeft: "20",
      zIndex: 0,
    },

    // NEPUŠAČKI DIO
    {
      _id: "65df5c8jkdd95e000e8bSXxc",
      title: "UDNP-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8dSX34d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "25",
      zIndex: 1,
    },
    {
      _id: "65df5c8d6f06fjas00e442dxy",
      title: "UDNP-2",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "25",
      zIndex: 1,
    },
    {
      _id: "65df5c8jfdd9gf000e8bdfxc",
      title: "UDNP-3",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "25",
      zIndex: 1,
    },
    {
      _id: "65df5c8dcv06fjk00e442dxy",
      title: "UDNP-4",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "25",
      zIndex: 1,
    },
    {
      _id: "65df5c8jkdd95e000e8bhjkc",
      title: "UDNP-5",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/2-stolice-obi%C4%8Dan_eazduk.svg",
      },
      isTable: true,
      objectPart: "65df5c8dSX34d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "25",
      zIndex: 1,
    },
    {
      _id: "65df5c8d6f06fjas00e4cv2dxy",
      title: "UDNP-6",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "25",
      zIndex: 1,
    },
    {
      _id: "65df5c8jfdd9gjk00e8bdfxc",
      title: "UDNP-7",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "25",
      zIndex: 1,
    },
    {
      _id: "65df5c8dcv06fjgh0e442dxy",
      title: "UDNP-8",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "25",
      zIndex: 1,
    },

    // LIJEVI DIO TERASE
    // STUPAC 1
    {
      _id: "65df5c8dVBT6fjgh0e442dxy",
      title: "LDT-R1-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "66Ff5c8dVBT6fjgh0e442dxy",
      title: "LDT-R1-2",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "65GH5c8dVBT6fjgh0e442dxy",
      title: "LDT-R1-3",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "65GHHB8dVBT6fjgh0e442dxy",
      title: "LDT-R1-4",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },

    //STUPAC 2
    {
      _id: "65gh5c8dVBT6fjgh0e442dxy",
      title: "LDT-R2-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "ffFf5c8dVBT6fjgh0e442dxy",
      title: "LDT-R2-2",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "65GH5c8dVff6fjgh0e442dxy",
      title: "LDT-R2-3",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "65GHHf8dffT6fjgh0e442dxy",
      title: "LDT-R2-4",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    //STUPAC 3
    {
      _id: "65gh5c8dvvT6fjgh0e442dxy",
      title: "LDT-R3-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "ffFf5cvvVBT6fjgh0e442dxy",
      title: "LDT-R3-2",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "65GH5c8dVfvvvjgh0e442dxy",
      title: "LDT-R3-3",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "65GHHf8nnnT6fjgh0e442dxy",
      title: "LDT-R3-4",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },

    // LIJEVI DIO TERASE
    // STUPAC 1
    {
      _id: "65df5c8yyyT6fjgh0e442dxy",
      title: "RDT-R1-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "66Ff5c8dVyT6fjgh0e442dxy",
      title: "RDT-R1-2",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "65GH5c8dyyT6fjgh0e442dxy",
      title: "RDT-R1-3",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "65GHHB8dVBT6fjgh0yy42dxy",
      title: "RDT-R1-4",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },

    //STUPAC 2
    {
      _id: "65gh5c8dVBT6fjgh0e44yyyy",
      title: "RDT-R2-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "ffFf5c8dVBT6fjsss0e442dxy",
      title: "RDT-R2-2",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "65GH5c8dVfssfjgh0e442dxy",
      title: "RDT-R2-3",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "65GHHf8dffsssjgh0e442dxy",
      title: "RDT-R2-4",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    //STUPAC 3
    {
      _id: "65gh5c8aaavT6fjgh0e442dxy",
      title: "RDT-R3-1",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "ffFf5cvvVBaaajgh0e442dxy",
      title: "RDT-R3-2",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "65GH5c8dVfvaaagh0e442dxy",
      title: "RDT-R3-3",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    {
      _id: "65GHHf8nnnT6fjgaaa0e442dxy",
      title: "RDT-R3-4",
      order: [],
      icon: {
        url: "https://res.cloudinary.com/dvg2o5azu/image/upload/v1709202643/cash-registar-icons/4-stolice-obi%C4%8Dan_ufutmx.svg",
      },
      isTable: true,
      objectPart: "65df5c8de834d6000e3ae8e0",
      objectSection: "65df5cjlk386ce000e51f3d5",
      rotate: "0",
      titleFontSize: ".7rem",
      titleTop: "-17",
      titleLeft: "10",
      zIndex: 1,
    },
    */
  ]);

  const [objectSection, setObjectSection] = useState([
    /*
    {
      _id: "6491d2gb4212b1b2b403eb52",
      title: "KOCKA",
      titleLeft: "80",
      titleTop: "5",
      titleFontSize: "1.2",
      layout: [
        {
          _id: "6491d2534212b1b2b403eb52",
          width: 20,
          height: 27,
          left: 20,
          top: 15,
        },
        {
          _id: "6451s2534212b1b2b403eb52",
          width: 20,
          height: 27,
          left: 50,
          top: 15,
        },
        {
          _id: "6491dv53421vb1b2b403eb52",
          width: 17,
          height: 27,
          left: 5,
          top: 63,
        },
        {
          _id: "6491d253421gb1bcb403eb52",
          width: 20,
          height: 27,
          left: 30,
          top: 63,
        },
        {
          _id: "6491d253421xb1bnkh03eb52",
          width: 20,
          height: 27,
          left: 60,
          top: 63,
        },
        {
          _id: "65df5c8d3c0bc9000e0lm182",
          width: 20,
          height: 27,
          left: 0,
          top: 4,
        },
      ],
    },
    {
      _id: "65df5c8d186e2b000e73a9f9",
      title: "KOCKA 2",
      titleLeft: "63",
      titleTop: "83",
      titleFontSize: "1.1",
      layout: [
        {
          _id: "65df5c8d3c0bc9000e045182",
          width: 27,
          height: 30,
          left: 66,
          top: 7,
        },
        {
          _id: "65df5c8d3c0bc9000e086182",
          width: 27,
          height: 30,
          left: 20,
          top: 16,
        },
        {
          _id: "65df5c8dd64dc6000ec29365",
          width: 27,
          height: 30,
          left: 20,
          top: 63,
        },
      ],
    },
    {
      _id: "65df5c8dSX34d6000e3ae8e0",
      title: "NEPUŠAČKI DIO",
      titleLeft: "17",
      titleTop: "3",
      titleFontSize: "0.85",
      layout: [
        {
          _id: "65df5c8jkdd95e000e8bSXxc",
          width: 40,
          height: 7,
          left: 30,
          top: 8,
        },
        {
          _id: "65df5c8d6f06fjas00e442dxy",
          width: 40,
          height: 7,
          left: 30,
          top: 18,
        },
        {
          _id: "65df5c8jfdd9gf000e8bdfxc",
          width: 40,
          height: 7,
          left: 30,
          top: 28,
        },
        {
          _id: "65df5c8dcv06fjk00e442dxy",
          width: 40,
          height: 7,
          left: 30,
          top: 39,
        },
        {
          _id: "65df5c8jkdd95e000e8bhjkc",
          width: 40,
          height: 7,
          left: 30,
          top: 58,
        },
        {
          _id: "65df5c8d6f06fjas00e4cv2dxy",
          width: 40,
          height: 7,
          left: 30,
          top: 68,
        },
        {
          _id: "65df5c8jfdd9gjk00e8bdfxc",
          width: 40,
          height: 7,
          left: 30,
          top: 78,
        },
        {
          _id: "65df5c8dcv06fjgh0e442dxy",
          width: 40,
          height: 7,
          left: 30,
          top: 88,
        },
      ],
    },

    {
      _id: "65df5c8dd386ce000e51f3d5",
      title: "GLAVNI DIO",
      titleLeft: "42",
      titleTop: "7",
      titleFontSize: "1.3",
      layout: [
        {
          _id: "65df5c8d186e2r000e73a9f9",
          left: 13,
          top: 2.5,
          width: 13,
          height: 75,
        },
        {
          _id: "65df5c8d186e2b000e71a9f9",
          left: 4,
          width: 10,
          height: 20,
          top: 60,
        },
        {
          _id: "65df5c8d9496cd000e2f9529",
          left: 4,
          width: 10,
          height: 20,
          top: 0,
        },
        {
          _id: "65df5c8d186e2x000e73a9f9",
          left: 85,
          top: 19,
          width: 10,
          height: 12,
        },
        {
          _id: "65df5c8d964d72000ece615b",
          left: 85,
          top: 30,
          width: 10,
          height: 12,
        },
        {
          _id: "65df5c8d7d6a2f000ec0eba7",
          left: 85,
          top: 55,
          width: 10,
          height: 12,
        },
        {
          _id: "65df5c8d3fb9d8000e6d97ac",
          left: 85,
          top: 66,
          width: 10,
          height: 12,
        },

        {
          _id: "34df5c8d3fb9d8000e6d97ac",
          left: 85,
          top: 2,
          width: 10,
          height: 12,
        },
        {
          _id: "24df5c8OIfbhd8000e6d97ac",
          left: 2,
          top: 85,
          width: 10,
          height: 12,
        },
        {
          _id: "24df5c8d3fbhd8000SDd97ac",
          left: 15,
          top: 85,
          width: 10,
          height: 12,
        },

        {
          _id: "24df5c8d3fbhdklp0SDd97ac",
          left: 63,
          top: 85,
          width: 10,
          height: 12,
        },
        {
          _id: "24df5c8d3fbhdklp0Sfg97ac",
          left: 85,
          top: 85,
          width: 7,
          height: 12,
        },
      ],
    },
    {
      _id: "65df5cjkd386ce000e51f3d5",
      title: "TRIBINA",
      titleLeft: "47",
      titleTop: "10",
      titleFontSize: "1.2",
      layout: [
        // STOLOVI
        {
          _id: "65df5c8jkdd95e000e8bdfxc",
          width: 8,
          height: 40,
          left: 10,
          top: 6,
        },
        {
          _id: "65df5c8d6f06fjc00e442dxy",
          width: 8,
          height: 40,
          left: 25,
          top: 6,
        },
        {
          _id: "65df5c8jfdd95e000e8bdfxc",
          width: 8,
          height: 40,
          left: 70,
          top: 6,
        },
        {
          _id: "65df5c8d6f06fjk00e442dxy",
          width: 8,
          height: 40,
          left: 85,
          top: 6,
        },

        //KAUČI
        {
          _id: "65df5c8d6f06mns00e442dxy",
          width: 100,
          height: 19,
          left: 1,
          top: 78,
        },
        {
          _id: "65df5c8d4ddVBe000e8b54xc",
          width: 8,
          height: 40,
          left: 13,
          top: 56,
        },
        {
          _id: "65df5c8d6f06fjk00e423dxy",
          width: 8,
          height: 40,
          left: 27,
          top: 56,
        },
        {
          _id: "65df5c8jkdd95e000e8b54xc",
          width: 8,
          height: 40,
          left: 40,
          top: 56,
        },
        {
          _id: "65df5c8d6f06flo00e412dxy",
          width: 8,
          height: 40,
          left: 55,
          top: 56,
        },
        {
          _id: "65df5c8d6f06fjk00e242dxy",
          width: 8,
          height: 40,
          left: 70,
          top: 56,
        },
        {
          _id: "65df5c8jkdd95e000g8bdfxc",
          width: 8,
          height: 40,
          left: 84,
          top: 56,
        },

        {
          _id: "65df5c8d4dd95e000e8b54xc",
          width: 10,
          height: 60,
          left: 0,
          top: 10,
        },
      ],
    },

    {
      _id: "6491d2gb4212b1b2b40asd52",
      title: "LIJEVI DIO TERASE",
      titleLeft: "17",
      titleTop: "3",
      titleFontSize: "0.85",
      layout: [
        {
          _id: "65df5c8dVBT6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 10,
          top: 85,
        },
        {
          _id: "66Ff5c8dVBT6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 10,
          top: 60,
        },
        {
          _id: "65GH5c8dVBT6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 10,
          top: 38,
        },
        {
          _id: "65GHHB8dVBT6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 10,
          top: 15,
        },

        // DRUGI STUPAC
        {
          _id: "65gh5c8dVBT6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 40,
          top: 85,
        },
        {
          _id: "ffFf5c8dVBT6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 40,
          top: 60,
        },
        {
          _id: "65GH5c8dVff6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 40,
          top: 38,
        },
        {
          _id: "65GHHf8dffT6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 40,
          top: 15,
        },

        // TREĆI STUPAC
        {
          _id: "65gh5c8dvvT6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 70,
          top: 85,
        },
        {
          _id: "ffFf5cvvVBT6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 70,
          top: 60,
        },
        {
          _id: "65GH5c8dVfvvvjgh0e442dxy",
          width: 17,
          height: 10,
          left: 70,
          top: 38,
        },
        {
          _id: "65GHHf8nnnT6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 70,
          top: 15,
        },
      ],
    },
    {
      _id: "6491d2gbas2b1b2b403eb52",
      title: "DESNI DIO TERASE",
      titleLeft: "17",
      titleTop: "3",
      titleFontSize: "0.85",
      layout: [
        {
          _id: "65df5c8yyyT6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 10,
          top: 85,
        },
        {
          _id: "66Ff5c8dVyT6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 10,
          top: 60,
        },
        {
          _id: "65GH5c8dyyT6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 10,
          top: 38,
        },
        {
          _id: "65GHHB8dVBT6fjgh0yy42dxy",
          width: 17,
          height: 10,
          left: 10,
          top: 15,
        },

        // DRUGI STUPAC
        {
          _id: "65gh5c8dVBT6fjgh0e44yyyy",
          width: 17,
          height: 10,
          left: 40,
          top: 85,
        },
        {
          _id: "ffFf5c8dVBT6fjsss0e442dxy",
          width: 17,
          height: 10,
          left: 40,
          top: 60,
        },
        {
          _id: "65GH5c8dVfssfjgh0e442dxy",
          width: 17,
          height: 10,
          left: 40,
          top: 38,
        },
        {
          _id: "65GHHf8dffsssjgh0e442dxy",
          width: 17,
          height: 10,
          left: 40,
          top: 15,
        },

        // TREĆI STUPAC
        {
          _id: "65gh5c8aaavT6fjgh0e442dxy",
          width: 17,
          height: 10,
          left: 70,
          top: 85,
        },
        {
          _id: "ffFf5cvvVBaaajgh0e442dxy",
          width: 17,
          height: 10,
          left: 70,
          top: 60,
        },
        {
          _id: "65GH5c8dVfvaaagh0e442dxy",
          width: 17,
          height: 10,
          left: 70,
          top: 38,
        },
        {
          _id: "65GHHf8nnnT6fjgaaa0e442dxy",
          width: 17,
          height: 10,
          left: 70,
          top: 15,
        },
      ],
    },
    */
  ]);

  const [objectPart, setObjectPart] = useState([
    /*
    {
      _id: "65df5c8de834d6000e3ae8e0",
      title: "Unutarnji dio",
      layout: [
        // KOCKA
        {
          objectSection: "6491d2gb4212b1b2b403eb52",
          width: 44.25,
          height: 28.25,
          left: 1,
          top: 1,
          isElevated: false,
        },

        // KOCKA 2
        {
          objectSection: "65df5c8d186e2b000e73a9f9",
          width: 27.5,
          height: 28.25,
          left: 53.75,
          top: 1,
          isElevated: true,
        },

        // GLAVNI DIO
        {
          objectSection: "65df5c8dSX34d6000e3ae8e0",
          width: 15,
          height: 98,
          left: 84,
          top: 1,
          isElevated: false,
        },

        // NEPUŠAČKI DIO
        {
          objectSection: "65df5c8dd386ce000e51f3d5",
          width: 80,
          height: 46.75,
          left: 1,
          top: 30,
          isElevated: false,
        },

        // TRIBINA
        {
          objectSection: "65df5cjkd386ce000e51f3d5",
          width: 80,
          height: 21.5,
          left: 1,
          top: 77.5,
          isElevated: true,
        },
      ],
    },
    {
      _id: "65df5c8de834d6000e3bh8e0",
      title: "Terasa",
      layout: [
        // LIJEVI DIO TERASE
        {
          objectSection: "6491d2gb4212b1b2b40asd52",
          width: 46,
          height: 98,
          left: 1,
          top: 1,
          isElevated: false,
        },

        //DESNI DIO TERASE
        {
          objectSection: "6491d2gbas2b1b2b403eb52",
          width: 46,
          height: 98,
          left: 52,
          top: 1,
          isElevated: false,
        },
      ],
    },

    */
  ]);

  const [users, setUsers] = useState([]);

  const [orders, setOrders] = useState([]);

  const [order, setOrder] = useState({
    drinks: [],
    total: 0,
    baseAmount: 0,
    operator: "",
    PDV: 25,
    discountAmount: 0,
    numberOfItems: 0,
    cashRegister: "kasa-1",
  });

  const [user, setUser] = useState({});

  const [files, setFiles] = useState([]);

  const value = {
    drinks,
    setDrinks,
    categories,
    setCategories,
    subCategories,
    setSubCategories,
    drinkCombinations,
    setDrinkCombinations,
    tables,
    setTables,
    objectSection,
    setObjectSection,
    objectPart,
    setObjectPart,
    order,
    setOrder,
    orders,
    setOrders,
    user,
    setUser,
    users,
    setUsers,
    files,
    setFiles,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
