import React, { useEffect, useState } from "react";
import "../styles/Page.scss";
import { useAppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";
import { serverURL } from "../utils/utils";
import Image from "../components/Image";

const Drinks = () => {
  const {
    drinks,
    categories,
    subCategories,
    drinkCombinations,
    order,
    setOrder,
    tables,
    setTables,
  } = useAppContext();
  const [elements, setElements] = useState([]);
  const [activeCategory, setActiveCategory] = useState(categories[0]?._id);
  const [orderId, setOrderId] = useState("");
  const [creatingOrder, setCreatingOrder] = useState(false);
  const addToOrder = (item, priceIndex, isCombination) => {
    if (!order?._id) {
      createOrder();
    }

    let orderDrinks = order?.drinks;

    if (!orderDrinks) {
      orderDrinks = [];
    }

    const existingOrderIndex = orderDrinks?.findIndex(
      (orderItem) =>
        orderItem._id === item._id && orderItem.priceIndex === priceIndex
    );

    if (existingOrderIndex > -1) {
      // Item exists, update the quantity
      const updatedOrder = [...orderDrinks];
      updatedOrder[existingOrderIndex].quantity += 1;

      //update the number of items in the order, base amount, total
      const numberOfItems = orderDrinks.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
      const total = orderDrinks.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
      const baseAmount = total / 1.25;

      setOrder({
        ...order,
        drinks: updatedOrder,
        numberOfItems: numberOfItems,
        baseAmount: baseAmount,
        total: total,
      });

      if (order?.table) {
        setTables((prevTables) => {
          const updatedTables = prevTables.map((prevTable) => {
            if (prevTable._id === order.table._id) {
              return { ...prevTable, order: order };
            }
            return prevTable;
          });
          return updatedTables;
        });
      }
    } else {
      // Item does not exist, add as new
      const newOrderItem = {
        _id: item._id,
        title: item.title,
        price: isCombination ? item.price : item.prices[priceIndex].price,
        quantity: 1,
        priceIndex: priceIndex,
        isCombination: isCombination,
      };

      const updatedOrder = [...orderDrinks, newOrderItem];

      //update the number of items in the order, base amount, total
      const numberOfItems = updatedOrder.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
      const total = updatedOrder.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
      const baseAmount = total / 1.25;

      setOrder({
        ...order,
        drinks: updatedOrder,
        numberOfItems: numberOfItems,
        baseAmount: baseAmount,
        total: total,
      });
    }
  };

  const removeFromOrder = (id, priceIndex, isCombination) => {
    let orderDrinks = order.drinks;

    if (orderDrinks.length === 1) {
      deleteOrder();

      if (order.table) {
        const updatedTables = tables.map((table) => {
          if (table?._id === order.table?._id) {
            return { ...table, order: null };
          }
          return table;
        });

        setTables(updatedTables);
        deleteOrderFromTableApi(order.table);
      }

      setOrder({
        drinks: [],
        total: 0,
        baseAmount: 0,
        operator: "",
        PDV: 25,
        discountAmount: 0,
        numberOfItems: 0,
        cashRegister: "kasa-1",
      });

      return;
    }

    const updatedOrder = orderDrinks?.filter(
      (orderItem) =>
        orderItem._id !== id ||
        (orderItem._id === id && orderItem.priceIndex !== priceIndex)
    );

    //update the number of items in the order, base amount, total
    const numberOfItems = updatedOrder.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    const total = updatedOrder.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    const baseAmount = total / 1.25;

    setOrder({
      ...order,
      drinks: updatedOrder,
      numberOfItems: numberOfItems,
      baseAmount: baseAmount,
      total: total,
    });
  };

  const updateQuantity = (id, priceIndex, isCombination, quantity) => {
    let orderDrinks = order.drinks;

    const numericQuantity = parseInt(quantity, 10);
    if (numericQuantity >= 1) {
      const updatedOrder = orderDrinks?.map((orderItem) => {
        if (orderItem._id === id && orderItem.priceIndex === priceIndex) {
          return { ...orderItem, quantity: numericQuantity };
        }
        return orderItem;
      });

      //update the number of items in the order, base amount, total
      const numberOfItems = updatedOrder.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
      const total = updatedOrder.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
      const baseAmount = total / 1.25;

      setOrder({
        ...order,
        drinks: updatedOrder,
        numberOfItems: numberOfItems,
        baseAmount: baseAmount,
        total: total,
      });
    }
  };

  const incrementQuantity = (id, priceIndex, isCombination) => {
    let orderDrinks = order.drinks;

    const updatedOrder = orderDrinks?.map((orderItem) => {
      if (orderItem._id === id && orderItem.priceIndex === priceIndex) {
        return { ...orderItem, quantity: orderItem.quantity + 1 };
      }
      return orderItem;
    });

    //update the number of items in the order, base amount, total
    const numberOfItems = updatedOrder.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    const total = updatedOrder.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    const baseAmount = total / 1.25;

    setOrder({
      ...order,
      drinks: updatedOrder,
      numberOfItems: numberOfItems,
      baseAmount: baseAmount,
      total: total,
    });
  };

  const decrementQuantity = (id, priceIndex, isCombination) => {
    let orderDrinks = order.drinks;

    const updatedOrder = orderDrinks?.map((orderItem) => {
      if (
        orderItem._id === id &&
        orderItem.priceIndex === priceIndex &&
        orderItem.quantity > 1
      ) {
        return { ...orderItem, quantity: orderItem.quantity - 1 };
      }
      return orderItem;
    });

    //update the number of items in the order, base amount, total
    const numberOfItems = updatedOrder.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    const total = updatedOrder.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    const baseAmount = total / 1.25;

    setOrder({
      ...order,
      drinks: updatedOrder,
      numberOfItems: numberOfItems,
      baseAmount: baseAmount,
      total: total,
    });
  };

  useEffect(() => {
    if (order?._id && order?.drinks?.length > 0) {
      updateOrderApi();
    }

    if (order?.table) {
      // check if the table order is null
      if (!order?.table?.order) {
        setOrderToTableApi(order.table);
      }

      setTables((prevTables) => {
        const updatedTables = prevTables.map((prevTable) => {
          if (prevTable?._id === order.table?._id) {
            return { ...prevTable, order: order };
          }
          return prevTable;
        });
        return updatedTables;
      });
    }
  }, [order?.drinks]);

  useEffect(() => {
    // add to Elements all subcategories but with type subcategory
    const subCategoriesWithCategory = subCategories.map((subCategory) => ({
      ...subCategory,
      type: "subCategory",
    }));

    // add to Elements all drinks but with type drink
    const drinksWithCategory = drinks.map((drink) => ({
      ...drink,
      type: "drink",
    }));

    // add to Elements all drinkCombinations but with type drinkCombination
    const drinkCombinationsWithCategory = drinkCombinations.map(
      (drinkCombination) => ({
        ...drinkCombination,
        type: "drinkCombination",
      })
    );

    // merge all arrays
    const allElements = [
      ...subCategoriesWithCategory,
      ...drinksWithCategory,
      ...drinkCombinationsWithCategory,
    ];

    setElements(allElements);
  }, [subCategories, drinks, drinkCombinations]);

  const updateOrderApi = () => {
    if (!order?._id) {
      return;
    }

    const token = localStorage.getItem("token");
    fetch(`${serverURL}/api/order/${order._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(order),
    })
      .then((res) => res.json())
      .then((data) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteOrder = () => {
    console.log("delete order");
    const token = localStorage.getItem("token");
    fetch(`${serverURL}/api/order/${order._id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setOrderId("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createOrder = () => {
    if (creatingOrder) {
      return;
    }
    setCreatingOrder(true);
    console.log("create order");
    const token = localStorage.getItem("token");
    fetch("${serverURL}/api/order", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(order),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("created order", data);
        setOrderId(data._id);
      });
  };

  const deleteOrderFromTableApi = (table) => {
    const token = localStorage.getItem("token");
    // fethc the /table/deleteOrder/:id route where id is the table id, put is the method and token is the authorization
    fetch(`${serverURL}/api/table/deleteOrder/${table._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const setOrderToTableApi = (table) => {
    const token = localStorage.getItem("token");
    fetch(`${serverURL}/api/table/setOrder/${table._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(order),
    })
      .then((res) => res.json())
      .then((data) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("orderId", orderId);
    // update the order with the given id
    if (orderId && orderId != "") {
      setOrder({ ...order, _id: orderId });
      updateOrderApi();
      console.log("AHjHJKAKJSFKJASBAKJF");
    }
  }, [orderId]);

  useEffect(() => {
    console.log("order changed", order);
  }, [order]);

  return (
    <div className="page">
      <div className="sidebar drinks">
        <div className="sidebar-top">
          <h5>Kategorije pića</h5>
        </div>

        <div className="sidebar-drinks-categories">
          {categories?.map((category, index) => (
            <button
              key={index}
              className={
                activeCategory === category._id
                  ? "active sidebar-drinks-category"
                  : "sidebar-drinks-category"
              }
              onClick={() => setActiveCategory(category._id)}
            >
              <div className="sidebar-drinks-category-image-container">
                <Image
                  src={category.image.url}
                  alt={category.title}
                  effect="blur"
                  width={"100%"}
                  height={"100%"}
                />
              </div>
              <div className="sidebar-drinks-category-text">
                <h6>{category.title}</h6>
                <p>{category.shortDescription}</p>
              </div>
            </button>
          ))}
        </div>
      </div>

      <div className="content">
        <div className="content-top">
          <h5> Proizvodi</h5>
          <div className="content-top-right">
            <div className="content-top-right-search">
              <input type="text" placeholder="Pretraži pića" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </div>

            <p>{drinks.length} proizvoda</p>
          </div>
        </div>
        <div className={"content-drinks active"}>
          <div className="content-drinks-container">
            {elements
              .filter((element) => element.category.includes(activeCategory))
              .sort((a, b) => a.layoutNumber - b.layoutNumber)
              .map((element, index) => {
                if (element.type === "subCategory") {
                  return (
                    <button key={index} className={"content-drink subcategory"}>
                      <div className="content-drink-image-container">
                        <Image
                          src={element.image.url}
                          alt={element.title}
                          effect="blur"
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>
                      <div className="content-drink-text">
                        <h6>{element.title}</h6>
                      </div>
                    </button>
                  );
                } else if (element.type === "drink") {
                  return element?.prices?.map((price, priceIndex) => (
                    <button
                      key={`${element._id}-${priceIndex}`}
                      className={
                        order?.drinks?.find(
                          (orderItem) =>
                            orderItem._id === element._id &&
                            orderItem.priceIndex === priceIndex
                        )
                          ? "content-drink active"
                          : "content-drink"
                      }
                      onClick={() => {
                        addToOrder(element, priceIndex, false);
                      }}
                    >
                      <div className="content-drink-image-container">
                        <Image
                          src={element.image.url}
                          alt={element.title}
                          effect="blur"
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>
                      <div className="content-drink-text">
                        <h6>
                          {element.title}{" "}
                          {element.unitSubtraction === "L" && (
                            <span>
                              - {price.subtraction} {element.unitSubtraction}
                            </span>
                          )}
                        </h6>
                        <p>{price.price + "0"} €</p>
                      </div>
                    </button>
                  ));
                } else {
                  return (
                    <button
                      key={index}
                      className={
                        order?.drinks?.find(
                          (orderDrink) => orderDrink._id === element._id
                        )
                          ? "content-drink active"
                          : "content-drink" // Unique key for each button
                      }
                      onClick={() => {
                        addToOrder(element, 0, true);
                      }}
                    >
                      <div className="content-drink-image-container">
                        <Image
                          src={element.image.url}
                          alt={element.title}
                          effect="blur"
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>
                      <div className="content-drink-text">
                        <h6>{element.title}</h6>
                        <p>{element.price + "0"} €</p>
                      </div>
                    </button>
                  );
                }
              })}
          </div>
        </div>
      </div>

      <div className="main-bar">
        <div className="main-bar-top">
          <h5> Narudžba</h5>
        </div>
        <div className="main-bar-table">
          <div className="main-bar-table-header">
            <div className="main-bar-table-header-text width-30">
              <h6>Naziv</h6>
            </div>
            <div className="main-bar-table-header-text width-15">
              <h6> Cijena</h6>
            </div>
            <div className="main-bar-table-header-text width-25">
              <h6> Količina</h6>
            </div>
            <div className="main-bar-table-header-text width-15">
              <h6> Iznos</h6>
            </div>
            <div className="main-bar-table-header-text width-15"></div>
          </div>
          <div className="main-bar-table-body">
            {order?.drinks?.map((item, index) => (
              <div
                className="main-bar-table-row"
                key={`${item._id}-${item.priceIndex}-${index}`}
              >
                <div className="main-bar-table-row-text width-30">
                  <h6>{item.title}</h6>
                </div>
                <div className="main-bar-table-row-text width-15">
                  <h6>{item.price?.toFixed(2)} €</h6>
                </div>
                <div className="main-bar-table-row-text width-25">
                  <div className="main-bar-table-row-quantity">
                    <button
                      className="main-bar-table-row-quantity-button"
                      onClick={() =>
                        decrementQuantity(
                          item._id,
                          item.priceIndex,
                          item.isCombination
                        )
                      }
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className="quantity-input"
                      value={item.quantity}
                      onChange={(e) =>
                        updateQuantity(
                          item._id,
                          item.priceIndex,
                          item.isCombination,
                          e.target.value
                        )
                      }
                      min="1"
                      style={{ width: "60px", textAlign: "center" }} // Možete prilagoditi stil kako biste bolje uklopili u vaš UI
                    />
                    <button
                      className="main-bar-table-row-quantity-button"
                      onClick={() =>
                        incrementQuantity(
                          item._id,
                          item.priceIndex,
                          item.isCombination
                        )
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="main-bar-table-row-text width-15">
                  <h6>{(item.price * item.quantity).toFixed(2)} €</h6>
                </div>
                <div className="main-bar-table-row-text width-15">
                  <button
                    className="main-bar-table-row-delete"
                    onClick={() =>
                      removeFromOrder(
                        item._id,
                        item.priceIndex,
                        item.isCombination
                      )
                    }
                  >
                    Izbriši
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="main-bar-table-footer">
            <div className="main-bar-table-footer-row">
              <div className="main-bar-table-footer-row-text width-25">
                <h5>Operater:</h5>
              </div>
              <div className="main-bar-table-footer-row-text width-25">
                <h6>
                  {order?.operator && order?.operator === ""
                    ? order?.operator
                    : "Niste prijavljeni"}
                </h6>
              </div>

              <div className="main-bar-table-footer-row-text width-25">
                <h5>Količina:</h5>
              </div>
              <div className="main-bar-table-footer-row-text width-25">
                <h6>{order?.numberOfItems + " proizvoda"}</h6>
              </div>
            </div>
            <div className="main-bar-table-footer-row">
              <div className="main-bar-table-footer-row-text width-15">
                <h5>Popust:</h5>
              </div>
              <div className="main-bar-table-footer-row-text width-15">
                <h6>{order?.discount ? order?.discount + " €" : "0.00 €"} €</h6>
              </div>
              <div className="main-bar-table-footer-row-text width-20">
                <h5>Osnovica: </h5>
              </div>
              <div className="main-bar-table-footer-row-text width-15">
                <h6>
                  {order?.baseAmount
                    ? order?.baseAmount?.toFixed(2) + " €"
                    : "0.00 €"}
                </h6>
              </div>
              <div className="main-bar-table-footer-row-text width-20">
                <h5>Ukupno:</h5>
              </div>
              <div className="main-bar-table-footer-row-text width-15">
                <h6>
                  {order?.total ? order?.total?.toFixed(2) + " €" : "0.00 €"}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="main-bar-note">
          <p>
            *Ukoliko niste spojeni na internet, narudžba će biti pohranjena na
            vaš uređaj i poslana kada se spojite na internet.
          </p>
        </div>
        <div className="main-bar-inputs-and-actions">
          <div className="main-bar-inputs">
            <div className="main-bar-inputs-row">
              <input type="number" placeholder="Upišite broj..." />
              <button>Submit</button>
            </div>
            <div className="main-bar-inputs-row">
              <button className="main-bar-inputs-row-button">7</button>
              <button className="main-bar-inputs-row-button">8</button>
              <button className="main-bar-inputs-row-button">9</button>
            </div>
            <div className="main-bar-inputs-row">
              <button className="main-bar-inputs-row-button">4</button>
              <button className="main-bar-inputs-row-button">5</button>
              <button className="main-bar-inputs-row-button">6</button>
            </div>
            <div className="main-bar-inputs-row">
              <button className="main-bar-inputs-row-button">1</button>
              <button className="main-bar-inputs-row-button">2</button>
              <button className="main-bar-inputs-row-button">3</button>
            </div>
            <div className="main-bar-inputs-row">
              <button className="main-bar-inputs-row-button">0</button>
              <button className="main-bar-inputs-row-button">.</button>
              <button className="main-bar-inputs-row-button">AC</button>
            </div>
          </div>
          <div className="main-bar-actions">
            <button className="main-bar-actions-button">Popust</button>
            <Link to={`/tables`} className="main-bar-actions-button">
              Stavi na stol
            </Link>

            <button className="main-bar-actions-button">
              Gotovinsko plaćanje
            </button>
            <button className="main-bar-actions-button">
              Kartično plaćanje
            </button>
            <button className="main-bar-actions-button">
              Ispis testnog računa
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drinks;
