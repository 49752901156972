import React, { useState, useEffect } from "react";
import "../styles/Page.scss";
import { useAppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import { getStaticImageFromKey } from "../utils/utils";

const Tables = () => {
  const { tables, setTables, objectSection, objectPart, order, setOrder } =
    useAppContext();
  const [selectedPart, setSelectedPart] = useState(0);
  const [partsDropdown, setPartsDropdown] = useState([]);
  const [sectionsDropdown, setSectionsDropdown] = useState([]);

  useEffect(() => {
    if (objectPart?.length > 0) {
      // loop through the objectPart array and create a new array with the title of each objectPart and true as the value of the dropdown
      const partDropdown = objectPart.map((item) => {
        return { _id: item?._id, dropdown: true };
      });
      setPartsDropdown(partDropdown);
    }
  }, [objectPart]);

  useEffect(() => {
    if (objectSection?.length > 0) {
      const sectionDropdown = objectSection.map((item) => {
        return { _id: item?._id, dropdown: true };
      });
      setSectionsDropdown(sectionDropdown);
    }
  }, [objectSection]);

  const toggleSectionDropdown = (sectionIndex) => {
    const updatedDropdown = sectionsDropdown.map((x) => {
      return x._id === sectionIndex ? { ...x, dropdown: !x.dropdown } : x;
    });
    setSectionsDropdown(updatedDropdown);
  };

  const checkIfSectionDropdownIsSelected = (sectionIndex) => {
    return sectionsDropdown.find((x) => x?._id === sectionIndex)?.dropdown;
  };

  const checkIfTableIsSelectedInOrder = (table) => {
    return order?.table?._id === table?._id;
  };

  const updateOrderApi = () => {
    console.log("update order");
    const token = localStorage.getItem("token");
    fetch(`https://cash-registar-server.vercel.app/api/order/${order._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(order),
    })
      .then((res) => res.json())
      .then((data) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const removeFromOrder = (id, priceIndex, isCombination) => {
    let orderDrinks = order.drinks;

    if (orderDrinks.length === 1) {
      deleteOrder();

      const updatedTables = tables.map((table) => {
        if (table?._id === order.table?._id) {
          return { ...table, order: null };
        }
        return table;
      });

      setTables(updatedTables);
      deleteOrderFromTableApi(order.table);

      setOrder({
        drinks: [],
        total: 0,
        baseAmount: 0,
        operator: "",
        PDV: 25,
        discountAmount: 0,
        numberOfItems: 0,
        cashRegister: "kasa-1",
      });

      return;
    }

    const updatedOrder = orderDrinks?.filter(
      (orderItem) =>
        orderItem._id !== id ||
        (orderItem._id === id && orderItem.priceIndex !== priceIndex)
    );

    //update the number of items in the order, base amount, total
    const numberOfItems = updatedOrder.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    const total = updatedOrder.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    const baseAmount = total / 1.25;

    setOrder({
      ...order,
      drinks: updatedOrder,
      numberOfItems: numberOfItems,
      baseAmount: baseAmount,
      total: total,
    });
  };

  const updateQuantity = (id, priceIndex, isCombination, quantity) => {
    let orderDrinks = order.drinks;

    const numericQuantity = parseInt(quantity, 10);
    if (numericQuantity >= 1) {
      const updatedOrder = orderDrinks?.map((orderItem) => {
        if (orderItem._id === id && orderItem.priceIndex === priceIndex) {
          return { ...orderItem, quantity: numericQuantity };
        }
        return orderItem;
      });

      //update the number of items in the order, base amount, total
      const numberOfItems = updatedOrder.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
      const total = updatedOrder.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
      const baseAmount = total / 1.25;

      setOrder({
        ...order,
        drinks: updatedOrder,
        numberOfItems: numberOfItems,
        baseAmount: baseAmount,
        total: total,
      });
    }
  };

  const incrementQuantity = (id, priceIndex, isCombination) => {
    let orderDrinks = order.drinks;

    const updatedOrder = orderDrinks?.map((orderItem) => {
      if (orderItem._id === id && orderItem.priceIndex === priceIndex) {
        return { ...orderItem, quantity: orderItem.quantity + 1 };
      }
      return orderItem;
    });

    //update the number of items in the order, base amount, total
    const numberOfItems = updatedOrder.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    const total = updatedOrder.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    const baseAmount = total / 1.25;

    setOrder({
      ...order,
      drinks: updatedOrder,
      numberOfItems: numberOfItems,
      baseAmount: baseAmount,
      total: total,
    });
  };

  const decrementQuantity = (id, priceIndex, isCombination) => {
    let orderDrinks = order.drinks;

    const updatedOrder = orderDrinks?.map((orderItem) => {
      if (
        orderItem._id === id &&
        orderItem.priceIndex === priceIndex &&
        orderItem.quantity > 1
      ) {
        return { ...orderItem, quantity: orderItem.quantity - 1 };
      }
      return orderItem;
    });

    //update the number of items in the order, base amount, total
    const numberOfItems = updatedOrder.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    const total = updatedOrder.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    const baseAmount = total / 1.25;

    setOrder({
      ...order,
      drinks: updatedOrder,
      numberOfItems: numberOfItems,
      baseAmount: baseAmount,
      total: total,
    });
  };

  useEffect(() => {
    if (order?._id && order?.drinks?.length > 0) {
      updateOrderApi();
    }

    if (order?.table) {
      // check if the table order is null
      if (!order?.table?.order) {
        setOrderToTableApi(order.table);
      }

      setTables((prevTables) => {
        const updatedTables = prevTables.map((prevTable) => {
          if (prevTable?._id === order.table?._id) {
            return { ...prevTable, order: order };
          }
          return prevTable;
        });
        return updatedTables;
      });
    }
  }, [order?.drinks]);

  const setOrderToTable = (table) => {
    setOrder({
      ...order,
      table: table,
    });

    setTables((prevTables) => {
      const updatedTables = prevTables.map((prevTable) => {
        if (prevTable?._id === table?._id) {
          return { ...prevTable, order: order };
        }
        return prevTable;
      });
      return updatedTables;
    });

    setOrderToTableApi(table);
  };

  const getOrderFromTable = (table) => {
    if (table?.order) {
      setOrder({
        ...table.order,
        table: table,
      });
    } else {
      setOrder({
        drinks: [],
        total: 0,
        baseAmount: 0,
        operator: "",
        PDV: 25,
        discountAmount: 0,
        numberOfItems: 0,
        cashRegister: "kasa-1",
        table: table,
      });
    }
  };

  const removeOrderFromTable = () => {
    // find the table in the order and if exist remove the order from the table
    setTables((prevTables) => {
      const updatedTables = prevTables.map((prevTable) => {
        if (prevTable?._id === order.table?._id) {
          return { ...prevTable, order: null };
        }
        return prevTable;
      });
      return updatedTables;
    });
    setOrder({
      drinks: [],
      total: 0,
      baseAmount: 0,
      operator: "",
      PDV: 25,
      discountAmount: 0,
      numberOfItems: 0,
      cashRegister: "kasa-1",
    });
  };

  const deleteOrder = () => {
    console.log("delete order");
    const token = localStorage.getItem("token");
    fetch(`https://cash-registar-server.vercel.app/api/order/${order._id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteOrderFromTableApi = (table) => {
    const token = localStorage.getItem("token");
    // fethc the /table/deleteOrder/:id route where id is the table id, put is the method and token is the authorization
    fetch(
      `https://cash-registar-server.vercel.app/api/table/deleteOrder/${table._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const setOrderToTableApi = (table) => {
    const token = localStorage.getItem("token");
    fetch(
      `https://cash-registar-server.vercel.app/api/table/setOrder/${table._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(order),
      }
    )
      .then((res) => res.json())
      .then((data) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="page tables">
      <div className="sidebar drinks">
        <div className="sidebar-top">
          <h5>Stolovi, Sekcije i Dijelovi Kafića</h5>
        </div>
        <div className="sidebar-objects-parts">
          {objectPart?.map((item, index) => {
            return (
              <div
                className="sidebar-objects-part"
                key={item?._id + "object" + index}
              >
                <button
                  className="sidebar-objects-part-button"
                  onClick={() => {
                    setSelectedPart(index);
                  }}
                >
                  <span>{item?.title}</span>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                    onClick={() => {
                      const updatedDropdown = partsDropdown.map((x, i) => {
                        return i === index
                          ? { ...x, dropdown: !x.dropdown }
                          : x;
                      });
                      setPartsDropdown(updatedDropdown);
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </button>
                <div
                  className={
                    partsDropdown[index]?.dropdown
                      ? "sidebar-objects-part-sections active"
                      : "sidebar-objects-part-sections"
                  }
                  key={item?._id + "object-sidebar" + index}
                >
                  {item?.layout.map((layout, index) => {
                    const section = objectSection.find(
                      (section) => section?.id === layout.objectSection
                    );
                    return (
                      <div className="sidebar-objects-section">
                        <button
                          className="sidebar-objects-section-button"
                          key={section?._id + "section-sidebar" + index}
                        >
                          <span>{section?.title}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                            onClick={() => toggleSectionDropdown(section?._id)}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m19.5 8.25-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        </button>

                        <div
                          className={
                            checkIfSectionDropdownIsSelected(section?._id)
                              ? "sidebar-objects-section-tables active"
                              : "sidebar-objects-section-tables"
                          }
                        >
                          {section?.layout.map((object, index) => {
                            const table = tables.find(
                              (table) => table?.id === object?.id
                            );
                            if (table?.isTable) {
                              return (
                                <button
                                  className={
                                    checkIfTableIsSelectedInOrder(table)
                                      ? "sidebar-objects-section-table selected"
                                      : "sidebar-objects-section-table"
                                  }
                                  onClick={() => {
                                    if (order?.table?._id === table?._id) {
                                      setOrder({
                                        drinks: [],
                                        total: 0,
                                        baseAmount: 0,
                                        operator: "",
                                        PDV: 25,
                                        discountAmount: 0,
                                        numberOfItems: 0,
                                        cashRegister: "kasa-1",
                                      });
                                    } else if (
                                      table?.isTable &&
                                      !order?.table?._id &&
                                      !table?.order?._id
                                    ) {
                                      setOrderToTable(table);
                                    } else if (
                                      table?.isTable &&
                                      order?.table?._id != table?._id
                                    ) {
                                      getOrderFromTable(table);
                                    }
                                  }}
                                  key={object?._id + "table-sidebar" + index}
                                >
                                  <span>{table?.title}</span>
                                  <div
                                    className="content-tables-section-object-order-total"
                                    style={
                                      table?.order?.drinks?.length > 0
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                  >
                                    <h6>
                                      {table?.order?.total
                                        ? table?.order?.total?.toFixed(2) + " €"
                                        : ""}
                                    </h6>
                                  </div>
                                </button>
                              );
                            }
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="content ">
        <div className="content-top">
          <h5>Stolovi</h5>
        </div>

        <div className="content-tables">
          {objectPart[selectedPart]?.layout?.map((item, index) => {
            const section = objectSection?.find((section) => {
              return section?.id === item?.objectSection;
            });

            return (
              <div
                className="content-tables-section"
                key={item._id + "section-content" + index}
                style={{
                  width: item.width + "%",
                  height: item.height + "%",
                  position: "absolute",
                  top: item.top + "%",
                  left: item.left + "%",
                  boxShadow: item.isElevated
                    ? "0 0 10px 0 rgba($color: $black, $alpha: 1)"
                    : "0 0 10px 0 rgba($color: $black, $alpha: 0.3)",
                }}
              >
                <h3
                  className="content-tables-section-title"
                  style={{
                    fontSize: section?.titleFontSize + "rem",
                    position: "absolute",
                    top: section?.titleTop + "%",
                    left: section?.titleLeft + "%",
                  }}
                >
                  {section?.title}
                </h3>
                {section?.layout?.map((object, index) => {
                  const table = tables.find(
                    (table) => table?.id === object?.id
                  );

                  return (
                    <button
                      className={
                        !table?.isTable
                          ? "content-tables-section-object "
                          : checkIfTableIsSelectedInOrder(table) &&
                            table?.isTable
                          ? "content-tables-section-object table selected"
                          : "content-tables-section-object table"
                      }
                      key={object?._id + "table-content" + index}
                      style={{
                        width: object.width + "%",
                        height: object.height + "%",
                        position: "absolute",
                        top: object.top + "%",
                        left: object.left + "%",
                        cursor: table?.isTable ? "pointer" : "default",
                        zIndex: table?.zIndex,
                        boxShadow: object.isElevated
                          ? "0 0 10px 0 rgba($color: $black, $alpha: 1)"
                          : "0 0 10px 0 rgba($color: $black, $alpha: 0.3)",
                      }}
                      onClick={() => {
                        if (order?.table?._id === table?._id) {
                          setOrder({
                            drinks: [],
                            total: 0,
                            baseAmount: 0,
                            operator: "",
                            PDV: 25,
                            discountAmount: 0,
                            numberOfItems: 0,
                            cashRegister: "kasa-1",
                          });
                        } else if (
                          table?.isTable &&
                          !order?.table?._id &&
                          !table?.order?._id
                        ) {
                          setOrderToTable(table);
                        } else if (
                          table?.isTable &&
                          order?.table?._id != table?._id
                        ) {
                          getOrderFromTable(table);
                        }
                      }}
                    >
                      <img
                        src={getStaticImageFromKey(table?.icon?.url)}
                        alt={table?.icon?.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          transform: `rotate(${table?.rotate}deg)`,
                          objectFit: "contain",
                        }}
                      />
                      <h5
                        className="content-tables-section-object-title"
                        style={{
                          fontSize: table?.titleFontSize + "rem",
                          position: "absolute",
                          top: table?.titleTop + "%",
                          left: table?.titleLeft + "%",
                          transform: `translateX(${table?.titleTranslateX}), translateY(${table?.titleTranslateY})`,
                          display: table?.isTable ? "block" : "none",
                          fontWeight: "300",
                        }}
                      >
                        {table?.title}
                      </h5>

                      <div
                        className="content-tables-section-object-order-total"
                        style={
                          table?.order?.drinks?.length > 0
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <h6>
                          {table?.order?.total
                            ? table?.order?.total?.toFixed(2) + " €"
                            : ""}
                        </h6>
                      </div>
                    </button>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className="main-bar">
        <div className="main-bar-top">
          <h5> Narudžba</h5>
        </div>
        <div className="main-bar-table">
          <div className="main-bar-table-header">
            <div className="main-bar-table-header-text width-30">
              <h6>Naziv</h6>
            </div>
            <div className="main-bar-table-header-text width-15">
              <h6> Cijena</h6>
            </div>
            <div className="main-bar-table-header-text width-25">
              <h6> Količina</h6>
            </div>
            <div className="main-bar-table-header-text width-15">
              <h6> Iznos</h6>
            </div>
            <div className="main-bar-table-header-text width-15"></div>
          </div>
          <div className="main-bar-table-body">
            {order?.drinks?.map((item, index) => (
              <div
                className="main-bar-table-row"
                key={`${item._id}-${item.priceIndex}-${index}`}
              >
                <div className="main-bar-table-row-text width-30">
                  <h6>{item.title}</h6>
                </div>
                <div className="main-bar-table-row-text width-15">
                  <h6>{item.price?.toFixed(2)} €</h6>
                </div>
                <div className="main-bar-table-row-text width-25">
                  <div className="main-bar-table-row-quantity">
                    <button
                      className="main-bar-table-row-quantity-button"
                      onClick={() =>
                        decrementQuantity(
                          item._id,
                          item.priceIndex,
                          item.isCombination
                        )
                      }
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className="quantity-input"
                      value={item.quantity}
                      onChange={(e) =>
                        updateQuantity(
                          item._id,
                          item.priceIndex,
                          item.isCombination,
                          e.target.value
                        )
                      }
                      min="1"
                      style={{ width: "60px", textAlign: "center" }} // Možete prilagoditi stil kako biste bolje uklopili u vaš UI
                    />
                    <button
                      className="main-bar-table-row-quantity-button"
                      onClick={() =>
                        incrementQuantity(
                          item._id,
                          item.priceIndex,
                          item.isCombination
                        )
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="main-bar-table-row-text width-15">
                  <h6>{(item.price * item.quantity).toFixed(2)} €</h6>
                </div>
                <div className="main-bar-table-row-text width-15">
                  <button
                    className="main-bar-table-row-delete"
                    onClick={() =>
                      removeFromOrder(
                        item._id,
                        item.priceIndex,
                        item.isCombination
                      )
                    }
                  >
                    Izbriši
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="main-bar-table-footer">
            <div className="main-bar-table-footer-row">
              <div className="main-bar-table-footer-row-text width-25">
                <h5>Operater:</h5>
              </div>
              <div className="main-bar-table-footer-row-text width-25">
                <h6>
                  {order?.operator && order?.operator === ""
                    ? order?.operator
                    : "Niste prijavljeni"}
                </h6>
              </div>

              <div className="main-bar-table-footer-row-text width-25">
                <h5>Količina:</h5>
              </div>
              <div className="main-bar-table-footer-row-text width-25">
                <h6>
                  {order?.numberOfItems > 0
                    ? order?.numberOfItems + " proizvoda"
                    : "0 proizvoda"}{" "}
                </h6>
              </div>
            </div>
            <div className="main-bar-table-footer-row">
              <div className="main-bar-table-footer-row-text width-15">
                <h5>Popust:</h5>
              </div>
              <div className="main-bar-table-footer-row-text width-15">
                <h6>0.00 €</h6>
              </div>
              <div className="main-bar-table-footer-row-text width-20">
                <h5>Osnovica: </h5>
              </div>
              <div className="main-bar-table-footer-row-text width-15">
                <h6>
                  {order?.baseAmount
                    ? order?.baseAmount?.toFixed(2) + " €"
                    : "0.00 €"}
                </h6>
              </div>
              <div className="main-bar-table-footer-row-text width-20">
                <h5>Ukupno:</h5>
              </div>
              <div className="main-bar-table-footer-row-text width-15">
                <h6>
                  {order?.total ? order?.total?.toFixed(2) + " €" : "0.00 €"}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="main-bar-note">
          <p>
            *Ukoliko niste spojeni na internet, narudžba će biti pohranjena na
            vaš uređaj i poslana kada se spojite na internet.
          </p>
        </div>
        <div className="main-bar-inputs-and-actions">
          <div className="main-bar-inputs">
            <div className="main-bar-inputs-row">
              <input type="number" placeholder="Upišite broj..." />
              <button>Submit</button>
            </div>
            <div className="main-bar-inputs-row">
              <button className="main-bar-inputs-row-button">7</button>
              <button className="main-bar-inputs-row-button">8</button>
              <button className="main-bar-inputs-row-button">9</button>
            </div>
            <div className="main-bar-inputs-row">
              <button className="main-bar-inputs-row-button">4</button>
              <button className="main-bar-inputs-row-button">5</button>
              <button className="main-bar-inputs-row-button">6</button>
            </div>
            <div className="main-bar-inputs-row">
              <button className="main-bar-inputs-row-button">1</button>
              <button className="main-bar-inputs-row-button">2</button>
              <button className="main-bar-inputs-row-button">3</button>
            </div>
            <div className="main-bar-inputs-row">
              <button className="main-bar-inputs-row-button">0</button>
              <button className="main-bar-inputs-row-button">.</button>
              <button className="main-bar-inputs-row-button">AC</button>
            </div>
          </div>
          <div className="main-bar-actions">
            <button className="main-bar-actions-button">Popust</button>
            <Link to="/drinks" className="main-bar-actions-button">
              Dodaj proizvode
            </Link>

            <button
              className="main-bar-actions-button"
              onClick={removeOrderFromTable}
            >
              Gotovinsko plaćanje
            </button>
            <button className="main-bar-actions-button">
              Kartično plaćanje
            </button>
            <button className="main-bar-actions-button">
              Ispis testnog računa
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tables;
