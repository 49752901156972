import React, { useState, useEffect } from "react";
import caffeImage from "../assets/images/caffe-bar/1.jpg";
import logo from "../assets/images/software-logo.svg";
import "../styles/LoginPage.scss";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { serverURL } from "../utils/utils";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    drinks,
    categories,
    drinkCombinations,
    tables,
    objectPart,
    objectSection,
    setUser,
    users,
    setUsers,
    setDrinks,
    setCategories,
    setDrinkCombinations,
    setSubCategories,
    setTables,
    setObjectPart,
    setObjectSection,
  } = useAppContext();
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    formData.forEach((value, key) => {
      data[key] = value;
    });

    fetch(`${serverURL}/api/users/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        if (
          data?.role === "admin" ||
          data?.role === "gazda" ||
          data?.role === "voditelj"
        ) {
          setUser(data);
        }
        fetchUsers();
        fetchDrinks();
        fetchCategories();
        fetchDrinkCombinations();
        fetchSubCategories();
        fetchObjectPart();
        fetchObjectSection();
        fetchTables();
        // set the user to local storage
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("token", data?.accessToken);
        navigate("/edit-drinks");
      })
      .catch((error) => {
        alert("Unešeni podaci nisu točni. Pokušajte ponovno.");
      });
  };

  // Fetch users
  const fetchUsers = () => {
    const token = localStorage.getItem("token"); // Retrieve the stored token
    fetch(`${serverURL}/api/users/all-users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Attach the token as a Bearer token
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUsers(data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  // Fetch drinks
  const fetchDrinks = () => {
    const token = localStorage.getItem("token"); // Retrieve the stored token
    fetch(`${serverURL}/api/drink`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Attach the token as a Bearer token
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setDrinks(data);
      })
      .catch((error) => {
        console.error("Error fetching drinks:", error);
        fetchDrinks();
      });
  };

  // Fetch categories
  const fetchCategories = () => {
    const token = localStorage.getItem("token"); // Retrieve the stored token
    fetch(`${serverURL}/api/category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Attach the token as a Bearer token
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        fetchCategories();
      });
  };

  // Fetch drink combinations
  const fetchDrinkCombinations = () => {
    const token = localStorage.getItem("token"); // Retrieve the stored token
    fetch(`${serverURL}/api/drinkCombination`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Attach the token as a Bearer token
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setDrinkCombinations(data);
      })
      .catch((error) => {
        console.error("Error fetching drink combinations:", error);
        fetchDrinkCombinations();
      });
  };

  // Fetch subcategories
  const fetchSubCategories = () => {
    const token = localStorage.getItem("token"); // Retrieve the stored token
    fetch(`${serverURL}/api/subCategory`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Attach the token as a Bearer token
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSubCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching subcategories:", error);
        fetchSubCategories();
      });
  };

  const fetchObjectPart = () => {
    const token = localStorage.getItem("token"); // Retrieve the stored token
    fetch(`${serverURL}/api/objectPart`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Attach the token as a Bearer token
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setObjectPart(data);
      })
      .catch((error) => {
        console.error("Error fetching ObjectPart:", error);
        fetchObjectPart();
      });
  };
  const fetchObjectSection = () => {
    const token = localStorage.getItem("token"); // Retrieve the stored token
    fetch(`${serverURL}/api/objectSection`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Attach the token as a Bearer token
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setObjectSection(data);
      })
      .catch((error) => {
        console.error("Error fetching ObjectPart:", error);
        fetchObjectSection();
      });
  };
  const fetchTables = () => {
    const token = localStorage.getItem("token"); // Retrieve the stored token
    fetch(`${serverURL}/api/table`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Attach the token as a Bearer token
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTables(data);
      })
      .catch((error) => {
        console.error("Error fetching ObjectPart:", error);
        fetchTables();
      });
  };

  useEffect(() => {
    if (
      users.length > 0 &&
      drinks.length > 0 &&
      categories.length > 0 &&
      drinkCombinations.length > 0 &&
      tables.length > 0 &&
      objectPart.length > 0 &&
      objectSection.length > 0
    ) {
      navigate("/drinks");
    }
  }, [users]);

  return (
    <div className="login-page">
      <div className="background-image-container">
        <img src={caffeImage} alt="caffe" />
      </div>{" "}
      <div className="login-form">
        <img src={logo} alt="logo" />
        <h2>
          Dobro došli u <span>Software</span>.
        </h2>
        <p>
          Ukoliko nemate račun možete se javiti na našu{" "}
          <Link to="/contact">kontakt</Link> stranicu. Ako ste zaboravili
          lozinku možete je resetirati <Link to="/reset-password">ovjde</Link>.
        </p>

        <form className="login" action="" onSubmit={handleSubmit}>
          <div className="form-row">
            <input type="text" placeholder="E-mail" name="email" id="email" />
          </div>{" "}
          <div className="form-row">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              name="password"
              id="password"
              autoComplete="off"
            />

            <button
              type="button"
              className="show-password"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              )}
            </button>
          </div>
          <button className="submit-btn" type="submit">
            prijava
          </button>
        </form>
        <div className="author">
          Autor software-a:
          <a href="https://msk.hr/" target="_blank" rel="noreferrer">
            msk.hr
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
