import React, { useState, useEffect } from "react";
import Select from "react-select";
import ImageUpload from "./ImageUpload";
import AreYouSure from "./AreYouSure";

const EditDrinkCombination = ({
  drinkCombination,
  categories,
  subCategories,
  drinks,
  setDrinkCombination,
  setDrinksCombinations,
}) => {
  const [editedDrinkCombination, setEditedDrinkCombination] = useState({
    title: "",
    price: "",
    category: [],
    subCategory: [],
    drinks: [],
    description: "",
    image: {},
    layoutNumber: 0,
  });
  const [optionsCategories, setOptionsCategories] = useState([]);
  const [optionsSubCategories, setOptionsSubCategories] = useState([]);
  const [optionsDrinks, setOptionsDrinks] = useState([]);
  const [image, setImage] = useState();
  const [ImageUploadActive, setImageUploadActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [areYouSure, setAreYouSure] = useState("idle");
  const [areYouSureActive, setAreYouSureActive] = useState(false);
  const [addedDrinks, setAddedDrinks] = useState([]);

  useEffect(() => {
    setEditedDrinkCombination({
      title: drinkCombination?.title || "",
      price: drinkCombination?.price || "",
      category: getCategoryOptions(drinkCombination?.category),
      subCategory: getSubCategoryOptions(drinkCombination?.subCategory),
      description: drinkCombination?.description || "",
      image: drinkCombination?.image || {},
      _id: drinkCombination?._id || undefined,
      layoutNumber: drinkCombination?.layoutNumber || undefined,
    });

    setAddedDrinks(getDrinksFromDrinkCombination() || []);

    console.log("drinkCombination._id", drinkCombination._id);
  }, [drinkCombination]);

  const getDrinksFromDrinkCombination = () => {
    if (!drinkCombination?.drinks || drinkCombination?.drinks.length === 0)
      return [];
    const drinks = drinkCombination?.drinks.map((drink) => {
      return {
        drink: drink.drink,
        subtraction: drink.subtraction,
      };
    });
    return drinks;
  };

  const getCategoryOptions = (category) => {
    if (!category || categories.length === 0 || category.length === 0)
      return [];
    const options = category?.map((c) => {
      return {
        value: c,
        label: categories.find((cat) => cat._id === c)?.title,
      };
    });
    return options;
  };

  const getSubCategoryOptions = (subCategory) => {
    if (!subCategory || subCategories.length === 0 || subCategory.length === 0)
      return [];
    console.log("subCategory", subCategory);
    const options = subCategories?.map((subCat) => {
      return {
        value: subCat,
        label: subCategories.find((sub) => sub._id === subCat)?.title,
      };
    });
    return options;
  };

  const getValuesFromOptions = (options) => {
    const values = options.map((option) => {
      return option.value;
    });
    return values;
  };

  useEffect(() => {
    setOptionsCategories(
      categories?.map((category) => ({
        value: category._id,
        label: category.title,
      }))
    );
  }, [categories]);

  useEffect(() => {
    setOptionsSubCategories(
      subCategories?.map((subCategory) => ({
        value: subCategory._id,
        label: subCategory.title,
      }))
    );
  }, [subCategories]);

  useEffect(() => {
    setOptionsDrinks(
      drinks?.map((drink) => ({
        value: drink._id,
        label: drink.title,
      }))
    );
  }, [drinks]);

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      borderColor: state.isFocused ? "#D1D5DB" : "gray", // Adjust the colors as needed
      borderRadius: 0,
      borderWidth: "0 0 1px 0",
      boxShadow: "none", // Removes the default shadow
      "&:hover": {
        borderColor: state.isFocused ? "#D1D5DB" : "gray",
      },
      color: "white",

      borderRight: "none",
    }),

    menu: (provided) => ({
      ...provided,
      backgroundColor: "white", // Adjust if you want a different background for the dropdown
      color: "white",
      borderRight: "none",

      //remove the padding
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#000" : "white",
      color: state.isSelected ? "white" : "black",
      cursor: "pointer",
      fontSize: "0.875rem",
      margin: 0,

      "&:hover": {
        backgroundColor: "#eee",
      },
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "#a0a0a0", // Placeholder color, adjust as needed
      fontSize: "0.875rem",
    }),
    input: (provided) => ({
      ...provided,
      color: "white", // Input text color
    }),
    // Add other customizations as needed

    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#2b2b2b",
      };
    },

    //remove the vertical line between the multi values
    multiValueRemove: (styles) => {
      return {
        ...styles,
        width: "1.3rem",
        color: "white",
        strokWidth: "0.8",
        height: "1.3rem",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "black",
          color: "white",
        },
      };
    },

    //;pwer the opacity of the vertical line between values and dropdown indicator
    indicatorSeparator: (styles) => {
      return {
        ...styles,
        backgroundColor: "rgba(255, 255, 255, 0.24)",
      };
    },

    //style the multi value label
    multiValueLabel: (styles) => {
      return {
        ...styles,
        color: "white",
        fontSize: "0.75rem",
      };
    },
  };

  const handleSubmit = (e) => {
    setLoading(true);

    const d = addedDrinks.map((drink) => {
      return {
        drink: drink.drink._id,
        subtraction: drink.subtraction,
      };
    });

    setEditedDrinkCombination({
      ...editedDrinkCombination,
      drinks: d,
    });

    const token = localStorage.getItem("token");
    e.preventDefault();

    if (
      editedDrinkCombination?.category?.length > 0 &&
      editedDrinkCombination?.category[0]?.value
    ) {
      editedDrinkCombination.category = getValuesFromOptions(
        editedDrinkCombination.category
      );
      setEditedDrinkCombination({
        ...editedDrinkCombination,
        category: editedDrinkCombination.category,
      });
    }

    if (
      editedDrinkCombination?.subCategory?.length > 0 &&
      editedDrinkCombination?.subCategory[0]?.value &&
      subCategories?.length > 0
    ) {
      editedDrinkCombination.subCategory = getValuesFromOptions(
        editedDrinkCombination.subCategory
      );
      setEditedDrinkCombination({
        ...editedDrinkCombination,
        subCategory: editedDrinkCombination.subCategory,
      });
    }

    if (!editedDrinkCombination?._id) {
      if (editedDrinkCombination._id) {
        delete editedDrinkCombination._id;
        console.log(editedDrinkCombination);
      }

      fetch(`https://cash-registar-server.vercel.app/api/drinkCombination`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(editedDrinkCombination),
      })
        .then((res) => res.json())
        .then((data) => {
          fetchDrinksCombinations();
          setDrinkCombination({
            title: "",
            image: {},
            category: [],
            subCategory: [],
            drinks: [],
            description: "",
          });
          setEditedDrinkCombination({
            title: "",
            image: {},
            category: [],
            subCategory: [],
            drinks: [],
            description: "",
          });
          setImage({});
          setAddedDrinks([]);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error adding drink:", error);
          setLoading(false);
        });
    } else {
      console.log("editedDrinkCombination", editedDrinkCombination._id);
      fetch(
        `https://cash-registar-server.vercel.app/api/drinkCombination/${editedDrinkCombination._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(editedDrinkCombination),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          fetchDrinksCombinations();
          setDrinkCombination({
            title: "",
            image: {},
            category: [],
            subCategory: [],
            drinks: [],
            description: "",
          });
          setEditedDrinkCombination({
            title: "",
            image: {},
            category: [],
            subCategory: [],
            drinks: [],
            description: "",
          });
          setAddedDrinks([]);
          setImage({});
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error updating drink combination:", error);
          setLoading(false);
        });
    }
  };

  const deleteFile = (file) => {
    const token = localStorage.getItem("token");
    fetch(`https://cash-registar-server.vercel.app/api/files/${file._id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    fetch(`${file.url}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const performDeletion = () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    if (drinkCombination?.image?.madeBy !== "admin") {
      deleteFile(drinkCombination?.image);
    }

    fetch(
      `https://cash-registar-server.vercel.app/api/drinkCombination/${drinkCombination._id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        fetchDrinksCombinations();
        setEditedDrinkCombination({
          title: "",
          price: "",
          category: [],
          subCategory: [],
          drinks: [],
          description: "",
          image: {},
        });

        setEditedDrinkCombination({
          title: "",
          price: "",
          category: [],
          subCategory: [],
          drinks: [],
          description: "",
          image: {},
        });

        setImage({});
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting drinkCombination:", error);
        setLoading(false);
      });
  };

  const fetchDrinksCombinations = () => {
    const token = localStorage.getItem("token");
    fetch("https://cash-registar-server.vercel.app/api/drinkCombination", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setDrinksCombinations(data);
      })
      .catch((error) => {
        console.error("Error fetching drinksCombinations:", error);
      });
  };

  useEffect(() => {
    if (areYouSure === "true" && drinkCombination?._id) {
      performDeletion();
    }
    // Reset the areYouSure state after action is taken.
    setAreYouSure("idle");
  }, [areYouSure]);

  useEffect(() => {
    if (image) {
      if (
        editedDrinkCombination?.image?.url &&
        editedDrinkCombination?.image?.url !== image?.url &&
        editedDrinkCombination?.image?.url !== drinkCombination?.image?.url &&
        editedDrinkCombination?.image?.madeBy !== "admin"
      ) {
        deleteFile(editedDrinkCombination?.image);
      }
      setEditedDrinkCombination({ ...editedDrinkCombination, image: image });
    }
  }, [image]);

  const deleteDrinkCombination = () => {
    // Trigger the AreYouSure component to show up.
    setAreYouSureActive(true);
  };

  // if the addedDrinks changes update the editedDrinkCombination
  useEffect(() => {
    if (addedDrinks.length === 0) return;
    setEditedDrinkCombination({
      ...editedDrinkCombination,
      drinks: addedDrinks,
    });

    console.log("addedDrinks", addedDrinks);
  }, [addedDrinks]);

  return (
    <>
      <form className="main-bar-edit" onSubmit={handleSubmit}>
        {" "}
        <div className={loading ? "loading active" : "loading "}>
          <div className="spinner"></div>
        </div>
        <div className="main-bar-edit-row">
          <div
            className="main-bar-edit-row-image"
            onClick={() => setImageUploadActive(true)}
          >
            {editedDrinkCombination?.image?.url ? (
              <>
                <img
                  src={editedDrinkCombination?.image?.url}
                  alt={editedDrinkCombination?.title}
                />
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={0.8}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>

                <span>Kliknite za dodavanje slike</span>
              </>
            )}
          </div>
          <div className="main-bar-edit-row-col">
            <div className="main-bar-edit-row-input">
              <label>Ime pića</label>
              <input
                type="text"
                value={editedDrinkCombination?.title}
                placeholder="Unesite ime pića"
                onChange={(e) =>
                  setEditedDrinkCombination({
                    ...editedDrinkCombination,
                    title: e.target.value,
                  })
                }
              />
            </div>{" "}
            <div className="main-bar-edit-row-input">
              <label>Cijena</label>
              <input
                type="number"
                value={editedDrinkCombination?.price}
                placeholder="Unesite cijenu"
                onChange={(e) =>
                  setEditedDrinkCombination({
                    ...editedDrinkCombination,
                    price: e.target.value,
                  })
                }
                min="0"
                step="any"
              />
              <div className="main-bar-edit-row-input-currency">€</div>
            </div>
          </div>
        </div>{" "}
        <div className="main-bar-edit-row">
          <div className="main-bar-edit-row-input">
            <label>Kategorija pića</label>
            <Select
              styles={customSelectStyles}
              placeholder="Odaberite kategoriju"
              isMulti={true}
              options={optionsCategories}
              value={editedDrinkCombination?.category}
              onChange={(e) =>
                setEditedDrinkCombination({
                  ...editedDrinkCombination,
                  category: e,
                })
              }
            />
          </div>
        </div>{" "}
        <div className="main-bar-edit-row">
          <div className="main-bar-edit-row-input">
            <label>Podkategorija pića</label>
            <Select
              styles={customSelectStyles}
              placeholder="Odaberite podkategoriju"
              isMulti={true}
              options={optionsSubCategories}
              value={editedDrinkCombination?.subCategory}
              onChange={(e) =>
                setEditedDrinkCombination({
                  ...editedDrinkCombination,
                  subCategory: e,
                })
              }
            />
          </div>
        </div>
        <div className="main-bar-edit-row">
          <div className="main-bar-edit-row-input">
            <label>Dodaj piće u kombinaciju</label>
            <Select
              styles={customSelectStyles}
              placeholder="Odaberite piće"
              isMulti={true}
              options={optionsDrinks}
              value={
                // get the drinks that are already added to the combination
                editedDrinkCombination?.drinks?.map((drink) => {
                  const id = drink.drink;
                  const d = drinks.find((d) => d._id === id);
                  return { value: id, label: d?.title };
                }) || []
              }
              onChange={(e) => {
                setAddedDrinks(
                  e.map((drink) => {
                    return {
                      drink: drink.value,
                      subtraction:
                        addedDrinks.find((d) => d.drink === drink.value)
                          ?.subtraction || 0,
                    };
                  })
                );
              }}
            />
          </div>
        </div>
        <div className="main-bar-edit-row-prices-table">
          <div className="main-bar-edit-row-prices-table-header">
            <div>
              <span>Ime pića</span>
            </div>
            <div>
              <span>Količina</span>
            </div>
            <div></div>
          </div>
          <div className="main-bar-edit-row-prices-table-body">
            {addedDrinks?.map((item, index) => {
              return (
                <div key={index} className="main-bar-edit-row-prices-table-row">
                  <div className="main-bar-edit-row-prices-table-row-title">
                    <h5>
                      {drinks.find((drink) => drink._id === item.drink)?.title}{" "}
                    </h5>
                  </div>

                  <div className="main-bar-edit-row-prices-table-row-input">
                    <input
                      type="number"
                      value={item.subtraction}
                      min="0"
                      step="any"
                      onChange={(e) => {
                        const value = e.target.value;
                        const newAddedDrinks = [...addedDrinks];
                        newAddedDrinks[index].subtraction = value;
                        setAddedDrinks(newAddedDrinks);
                      }}
                    />
                    <div className="main-bar-edit-row-input-currency">
                      {
                        drinks.find((drink) => drink._id === item.drink)
                          ?.unitSubtraction
                      }
                    </div>
                  </div>

                  <button
                    className="main-bar-edit-row-prices-table-row-button"
                    onClick={() => {
                      const newAddedDrinks = [...addedDrinks];
                      newAddedDrinks.splice(index, 1);
                      setAddedDrinks(newAddedDrinks);
                    }}
                    type="button"
                  >
                    Obriši
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="main-bar-edit-row-input">
          <label>Redni broj </label>
          <input
            type="number"
            value={editedDrinkCombination?.layoutNumber}
            placeholder="Unesite redni broj"
            onChange={(e) =>
              setEditedDrinkCombination({
                ...editedDrinkCombination,
                layoutNumber: e.target.value,
              })
            }
          />
        </div>
        <div className="main-bar-edit-row-input">
          <label>Opis pića</label>
          <textarea
            value={editedDrinkCombination?.description}
            placeholder="Upišite opis "
            onChange={(e) =>
              setEditedDrinkCombination({
                ...editedDrinkCombination,
                description: e.target.value,
              })
            }
          />
        </div>
        <div className="main-bar-edit-row bottom-buttons">
          <button
            className="main-bar-edit-row-button"
            onClick={() => {
              if (drinkCombination?._id) {
                deleteDrinkCombination();
                setAddedDrinks([]);
              } else {
                if (image?.url) {
                  deleteFile(image);
                }
                setImage({});
                setAddedDrinks([]);
                setDrinkCombination({
                  title: "",
                  price: "",
                  category: [],
                  subCategory: [],
                  drinks: [],
                  description: "",
                  image: {},
                });
                setEditedDrinkCombination({
                  title: "",
                  price: "",
                  category: [],
                  subCategory: [],
                  drinks: [],
                  description: "",
                  image: {},
                });
              }
            }}
            type="button"
          >
            {drinkCombination?._id && drinkCombination._id !== ""
              ? "Obriši kombinaciju"
              : "Odustani"}
          </button>
          <button className="main-bar-edit-row-button" type="submit">
            Spremi promjene
          </button>
        </div>
      </form>
      <ImageUpload
        image={image}
        setImage={setImage}
        category={"drinkCombination"}
        active={ImageUploadActive}
        setActive={setImageUploadActive}
      />
      <AreYouSure
        x={areYouSure}
        setX={setAreYouSure}
        active={areYouSureActive}
        setActive={setAreYouSureActive}
        title={"Obriši kombinaciju pića"}
        message={"Jeste li sigurni da želite obrisati ovu kombinaciju pića?"}
      />
    </>
  );
};

export default EditDrinkCombination;
