import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/NavBar.scss";
import usersWhite from "../assets/icons/users-white.png";
import drinkWhite from "../assets/icons/drink-white.png";
import tablesWhite from "../assets/icons/tables-white.png";
import documentTablesWhite from "../assets/icons/documents-table-white.png";
import statisticsWhite from "../assets/icons/statistics-white.png";
import calendarWhite from "../assets/icons/calendar-white.png";
import { useAppContext } from "../context/AppContext";

const NavBar = () => {
  const { user } = useAppContext();
  return (
    <div className="navbar">
      <div className="navbar-top">
        <h5> Meni</h5>
      </div>
      <div className="navbar-menu">
        {/* <NavLink to="/drinks" className="navbar-link" end>
          <img src={drinkWhite} alt="drink" />
          <span>Pića</span>
        </NavLink>
        <NavLink to="/tables" className="navbar-link">
          <img src={tablesWhite} alt="drink" />
          <span>Stolovi</span>
        </NavLink>
        <NavLink to="/orders" className="navbar-link">
          <img src={documentTablesWhite} alt="drink" />
          <span>Narudžbe</span>
        </NavLink> */}
        {/* <NavLink
          to="/inventory"
          className="navbar-link"
          style={{
            display:
              user?.role === "admin" ||
              user?.role === "voditelj" ||
              user?.role === "gazda"
                ? "flex"
                : "none",
          }}
        >
          <img src={documentTablesWhite} alt="drink" />
          <span>Inventura</span>
        </NavLink> */}
        {/* <NavLink
          style={{
            display:
              user?.role === "admin" ||
              user?.role === "voditelj" ||
              user?.role === "gazda"
                ? "flex"
                : "none",
          }}
          to="/stock"
          className="navbar-link"
        >
          <img src={documentTablesWhite} alt="drink" />
          <span> Zaliha</span>
        </NavLink> */}

        {/* <NavLink
          style={{
            display:
              user?.role === "admin" ||
              user?.role === "voditelj" ||
              user?.role === "gazda"
                ? "flex"
                : "none",
          }}
          to="/users"
          className="navbar-link"
        >
          <img src={usersWhite} alt="drink" />
          <span>Korisnici</span>
        </NavLink> */}
        {/* <NavLink
          to="/statistics"
          className="navbar-link"
          style={{
            display:
              user?.role === "admin" ||
              user?.role === "voditelj" ||
              user?.role === "gazda"
                ? "flex"
                : "none",
          }}
        >
          <img src={statisticsWhite} alt="drink" />
          <span>Statistika</span>
        </NavLink> */}
        {/* <NavLink
          to="/shifts"
          className="navbar-link"
          style={{
            display:
              user?.role === "admin" ||
              user?.role === "voditelj" ||
              user?.role === "gazda"
                ? "flex"
                : "none",
          }}
        >
          <img src={calendarWhite} alt="drink" />
          <span>Smjene</span>
        </NavLink> */}
        <NavLink
          to="/edit-drinks"
          className="navbar-link"
          end
          style={{
            display:
              user?.role === "admin" ||
              user?.role === "voditelj" ||
              user?.role === "gazda"
                ? "flex"
                : "none",
          }}
        >
          <img src={drinkWhite} alt="drink" />
          <span>Uredi Pića</span>
        </NavLink>
      </div>
    </div>
  );
};

export default NavBar;
