import React from "react";
import caffeImage from "../assets/images/caffe-bar/1.jpg";
import logo from "../assets/images/software-logo.svg";
import "../styles/LoginPage.scss";
import { Link } from "react-router-dom";
const Contact = () => {
  return (
    <div className="login-page">
      <div className="background-image-container">
        <img src={caffeImage} alt="caffe" />
      </div>{" "}
      <div className="login-form">
        <img src={logo} alt="logo" />
        <h2>Kontaktirajte nas.</h2>
        <p>
          Ukoliko nemate račun možete se javiti na našu{" "}
          <Link to="/contact">kontakt</Link> stranicu. Ako ste zaboravili
          lozinku možete je resetirati <Link to="/reset-password">ovjde</Link>.
        </p>

        <form className="login" action="">
          <div className="form-row">
            <input type="text" placeholder="E-mail" name="email" id="email" />
          </div>
          <div className="form-row">
            <input
              type="telephone"
              placeholder="Telefon"
              name="telephone"
              id="telephone"
            />
          </div>
          <div className="form-row">
            <textarea
              placeholder="Poruka"
              name="message"
              id="message"
            ></textarea>
          </div>{" "}
          <div className="form-row">
            <button className="submit-btn" type="submit">
              Pošalji poruku
            </button>
          </div>
        </form>
        <div className="author">
          Autor software-a:
          <a href="https://msk.hr/" target="_blank" rel="noreferrer">
            msk.hr
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
