import React, { useState } from "react";
import "../styles/TopMenuBar.scss";
import userIcon from "../assets/icons/user-white.png";
import { useAppContext } from "../context/AppContext";

const TopMenuBar = () => {
  const [activeMenu, setActiveMenu] = useState(null);

  const { user } = useAppContext();

  return (
    <div className="top-menu-bar">
      <div className="top-menu-bar-left">
        <div className="top-menu-bar-left-button-container">
          <button
            className="top-menu-bar-left-button"
            onMouseEnter={() => setActiveMenu("upute")}
          >
            Upute
          </button>
          <div
            className={
              activeMenu === "upute"
                ? "top-menu-bar-left-submenu active"
                : "top-menu-bar-left-submenu"
            }
          >
            <button className="top-menu-bar-left-submenu-button">
              Osnovne upute za operatere{" (konobare)"}
            </button>
            <button className="top-menu-bar-left-submenu-button">
              Osnovne upute za Voditelje
            </button>
          </div>
        </div>{" "}
        <div className="top-menu-bar-left-button-container">
          <button
            className="top-menu-bar-left-button"
            onMouseEnter={() => setActiveMenu("dodatne")}
          >
            Dodatne mogućnosti
          </button>
          <div
            className={
              activeMenu === "dodatne"
                ? "top-menu-bar-left-submenu active"
                : "top-menu-bar-left-submenu"
            }
          >
            <button className="top-menu-bar-left-submenu-button">
              Naručivanje preko QR koda na stolu
            </button>
            <button className="top-menu-bar-left-submenu-button">
              Naručivanje preko mobitela
            </button>
            <button className="top-menu-bar-left-submenu-button">
              Web stranica
            </button>
            <button className="top-menu-bar-left-submenu-button">
              Raspored smjena
            </button>{" "}
          </div>
        </div>
        <div className="top-menu-bar-left-button-container">
          <button
            className="top-menu-bar-left-button"
            onMouseEnter={() => setActiveMenu("softver")}
          >
            O Softveru
          </button>
          <div
            className={
              activeMenu === "softver"
                ? "top-menu-bar-left-submenu active"
                : "top-menu-bar-left-submenu"
            }
          >
            <button className="top-menu-bar-left-submenu-button">
              Način rada softvera
            </button>
            <button className="top-menu-bar-left-submenu-button">
              Kako je napravljen softver
            </button>
            <button className="top-menu-bar-left-submenu-button">
              Tko ga je napravio
            </button>
          </div>
        </div>
        <div className="top-menu-bar-left-button-container">
          <button
            className="top-menu-bar-left-button"
            onMouseEnter={() => setActiveMenu("pomoc")}
          >
            Pomoć
          </button>
          <div
            className={
              activeMenu === "pomoc"
                ? "top-menu-bar-left-submenu active"
                : "top-menu-bar-left-submenu"
            }
          >
            <button className="top-menu-bar-left-submenu-button">
              WhatsApp
            </button>
            <button className="top-menu-bar-left-submenu-button">Mail</button>
            <button className="top-menu-bar-left-submenu-button">
              Nazovite me: 091 / 390 - 5524
            </button>
          </div>
        </div>
        <button
          className={
            activeMenu === null ? "close-button" : "close-button active"
          }
          onMouseMove={() => setActiveMenu(null)}
        ></button>
      </div>
      <div
        className="top-menu-bar-right"
        style={user?.firstName ? { display: "flex" } : { display: "none" }}
      >
        <img
          src={
            user?.image?.url
              ? "https://cash-registar-server.vercel.app/api/upload/" +
                user?.image?.url
              : userIcon
          }
          alt="user"
          className="top-menu-bar-right-icon-img"
        />
        <span>{user?.firstName + " " + user?.lastName}</span>
        <p
          style={{
            textTransform: "capitalize",
          }}
        >
          {user?.role}
        </p>
      </div>
    </div>
  );
};

export default TopMenuBar;
