import React from "react";
import caffeImage from "../assets/images/caffe-bar/1.jpg";
import logo from "../assets/images/software-logo.svg";
import "../styles/LoginPage.scss";
import { Link } from "react-router-dom";
const ResetPassword = () => {
  return (
    <div className="login-page">
      <div className="background-image-container">
        <img src={caffeImage} alt="caffe" />
      </div>{" "}
      <div className="login-form">
        <img src={logo} alt="logo" />
        <h2>Zaboravili ste lozinku?</h2>
        <p>
          Unesite e-mail adresu koju ste koristili prilikom registracije i
          poslati ćemo vam link za resetiranje lozinke.
        </p>
        <form className="login" action="">
          <div className="form-row">
            <input type="text" placeholder="E-mail" name="email" id="email" />
          </div>{" "}
          <div className="form-row">
            <button className="submit-btn" type="submit">
              RESETIRAJ LOZINKU
            </button>
          </div>
        </form>{" "}
        <div className="author">
          Autor software-a:
          <a href="https://msk.hr/" target="_blank" rel="noreferrer">
            msk.hr
          </a>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
