import { BrowserRouter, Route, Routes } from "react-router-dom";
import Inventory from "./pages/Inventory";
import Stock from "./pages/Stock";
import Tables from "./pages/Tables";
import Users from "./pages/Users";
import Drinks from "./pages/Drinks";
import Orders from "./pages/Orders";
import NavBar from "./components/NavBar";
import TopMenuBar from "./components/TopMenuBar";
import BottomMenuBar from "./components/BottomMenuBar";
import Statistics from "./pages/Statistics";
import Shifts from "./pages/Shifts";
import EditDrinks from "./pages/EditDrinks";
import { AppProvider } from "./context/AppContext";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import Contact from "./pages/Contact";

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <NavBar />
        <TopMenuBar />
        <BottomMenuBar />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/drinks" element={<Drinks />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/stock" element={<Stock />} />
          <Route path="/tables" element={<Tables />} />
          <Route path="/users" element={<Users />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="*" element={<Drinks />} />
          <Route path="/statistics" element={<Statistics />} />
          <Route path="/shifts" element={<Shifts />} />
          <Route path="/edit-drinks" element={<EditDrinks />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
