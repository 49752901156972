import React from "react";
import "../styles/AreYouSure.scss";

const AreYouSure = ({ x, setX, active, setActive, title, message }) => {
  return (
    <div className="are-you-sure" style={{ display: active ? "flex" : "none" }}>
      <div className="are-you-sure-whole-background"></div>
      <div className="are-you-sure-header">
        <span>Poruka</span>
        <button
          className="x-button"
          onClick={() => {
            setX("false");
            setActive(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="are-you-sure-message">
        <h3>{title}</h3>
        <p>{message}</p>
      </div>

      <div className="are-you-sure-buttons">
        <button
          className="are-you-sEue-button"
          onClick={() => {
            setX("false");
            setActive(false);
          }}
        >
          Odustani
        </button>
        <button
          className="are-you-sue-button"
          onClick={() => {
            setX("true");
            setActive(false);
          }}
        >
          Nastavi
        </button>
      </div>
    </div>
  );
};

export default AreYouSure;
