import React, { useEffect, useState } from "react";
import "../styles/Page.scss";
import EditCategory from "../components/EditCategory";
import EditDrinksCombination from "../components/EditDrinksCombination";
import EditSubCategory from "../components/EditSubCategory";
import EditDrink from "../components/EditDrink";
import { useAppContext } from "../context/AppContext";
import { LazyLoadImage } from "react-lazy-load-image-component";

const EditDrinks = () => {
  const {
    categories,
    setCategories,
    subCategories,
    setSubCategories,
    drinks,
    setDrinks,
    drinkCombinations,
    setDrinkCombinations,
  } = useAppContext();
  const [activeCategory, setActiveCategory] = useState(categories[0]?._id);
  const [selectCategoryOrDrink, setSelectCategoryOrDrink] =
    useState("category");

  const [editDrink, setEditDrink] = useState({
    title: "",
    shortDescription: "",
    prices: [],
    image: {},
    category: "",
    subCategory: "",
    image: {},
  });

  const [editCategory, setEditCategory] = useState({
    title: "",
    shortDescription: "",
    image: {},
  });

  const [editSubCategory, setEditSubCategory] = useState({
    title: "",
    shortDescription: "",
    image: {},
    category: [],
  });

  const [editDrinkCombination, setEditDrinkCombination] = useState({
    title: "",
    shortDescription: "",
    image: {},
    category: "",
    drinks: [],
  });

  const [elements, setElements] = useState([]);

  useEffect(() => {
    // add to Elements all subcategories but with type subcategory
    const subCategoriesWithCategory = subCategories.map((subCategory) => ({
      ...subCategory,
      type: "subCategory",
    }));

    // add to Elements all drinks but with type drink
    const drinksWithCategory = drinks.map((drink) => ({
      ...drink,
      type: "drink",
    }));

    // add to Elements all drinkCombinations but with type drinkCombination
    const drinkCombinationsWithCategory = drinkCombinations.map(
      (drinkCombination) => ({
        ...drinkCombination,
        type: "drinkCombination",
      })
    );

    // merge all arrays
    const allElements = [
      ...subCategoriesWithCategory,
      ...drinksWithCategory,
      ...drinkCombinationsWithCategory,
    ];

    setElements(allElements);
  }, [subCategories, drinks, drinkCombinations]);

  return (
    <div className="page">
      <div className="sidebar">
        <div className="sidebar-top">
          <h5>Kategorije pića</h5>
        </div>

        <div className="sidebar-drinks-categories edit-drinks-sidebar">
          {categories.map((category, index) => (
            <button
              key={index}
              className={
                activeCategory === category._id
                  ? "active sidebar-drinks-category"
                  : "sidebar-drinks-category"
              }
            >
              <div className="sidebar-drinks-category-image-container">
                <LazyLoadImage
                  src={category.image.url}
                  alt={category.title}
                  effect="blur"
                  fit="cover"
                  width="100%"
                  height="100%"
                  onClick={() => setActiveCategory(category._id)}
                />
              </div>
              <div className="sidebar-drinks-category-text">
                <h6 onClick={() => setActiveCategory(category._id)}>
                  {category.title}
                </h6>
                <button
                  className="edit-category-button"
                  onClick={() => {
                    setEditCategory(category);
                    setSelectCategoryOrDrink("category");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                  </svg>

                  <p>Uredi kategoriju</p>
                </button>{" "}
              </div>
            </button>
          ))}
        </div>

        <div className="sidebar-bottom">
          <button
            className="sidebar-bottom-button"
            onClick={() => {
              setSelectCategoryOrDrink("category");
              setEditCategory({
                title: "",
                shortDescription: "",
                image: {},
              });
            }}
          >
            Dodaj kategoriju
          </button>
          <button
            className="sidebar-bottom-button"
            onClick={() => {
              setSelectCategoryOrDrink("subCategory");
              setEditSubCategory({
                title: "",
                shortDescription: "",
                image: {},
                category: [],
              });
            }}
          >
            Dodaj podkategoriju
          </button>
          <button
            className="sidebar-bottom-button"
            onClick={() => {
              setSelectCategoryOrDrink("drinkCombination");
              setEditDrinkCombination({
                title: "",
                shortDescription: "",
                image: {},
                drinks: [],
                category: "",
                _id: "",
                price: 0,
              });
            }}
          >
            Dodaj kombinaciju
          </button>
          <button
            className="sidebar-bottom-button"
            onClick={() => {
              setSelectCategoryOrDrink("drink");
              setEditDrink({
                title: "",
                shortDescription: "",
                prices: [],
                image: {},
                category: "",
                subCategory: "",
              });
            }}
          >
            Dodaj piće
          </button>
        </div>
      </div>

      <div className="content">
        <div className="content-top">
          <h5> Proizvodi</h5>
          <div className="content-top-right">
            <div className="content-top-right-search">
              <input type="text" placeholder="Pretraži pića" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </div>

            <p>{drinks.length} proizvoda</p>
          </div>
        </div>
        <div className={"content-drinks active"}>
          <div className="content-drinks-container">
            {elements
              .filter((element) => element.category.includes(activeCategory))
              .sort((a, b) => a.layoutNumber - b.layoutNumber)
              .map((element, index) => {
                if (element.type === "subCategory") {
                  return (
                    <button
                      key={index}
                      className={
                        editSubCategory === element &&
                        selectCategoryOrDrink === "subCategory"
                          ? "active content-drink subcategory"
                          : "content-drink subcategory"
                      }
                      onClick={() => {
                        setEditSubCategory(element);
                        setSelectCategoryOrDrink("subCategory");
                      }}
                    >
                      <div className="content-drink-image-container">
                        <LazyLoadImage
                          src={element.image?.url}
                          alt={element.title}
                          effect="blur"
                          fit="cover"
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <div className="content-drink-text">
                        <h6>{element.title}</h6>
                      </div>
                    </button>
                  );
                } else if (element.type === "drink") {
                  return (
                    <button
                      key={index}
                      className={
                        editDrink === element &&
                        selectCategoryOrDrink === "drink"
                          ? "active content-drink"
                          : "content-drink"
                      }
                      onClick={() => {
                        setEditDrink(element);
                        setSelectCategoryOrDrink("drink");
                      }}
                    >
                      <div className="content-drink-image-container">
                        <LazyLoadImage
                          src={element.image?.url}
                          alt={element.title}
                          effect="blur"
                          fit="cover"
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <div className="content-drink-text">
                        <h6>{element.title}</h6>
                        <button className="edit-drink-button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                          </svg>

                          <p>Uredi piće</p>
                        </button>
                      </div>
                    </button>
                  );
                } else if (element.type === "drinkCombination") {
                  return (
                    <button
                      key={index}
                      className={
                        editDrinkCombination === element &&
                        selectCategoryOrDrink === "drinkCombination"
                          ? "active content-drink"
                          : "content-drink"
                      }
                      onClick={() => {
                        setEditDrinkCombination(element);
                        setSelectCategoryOrDrink("drinkCombination");
                      }}
                    >
                      <div className="content-drink-image-container">
                        <LazyLoadImage
                          src={element.image?.url}
                          alt={element.title}
                          effect="blur"
                          fit="cover"
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <div className="content-drink-text">
                        <h6>{element.title}</h6>

                        <button className="edit-drink-button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                          </svg>
                          <p>Uredi </p>{" "}
                        </button>
                      </div>
                    </button>
                  );
                }
              })}
          </div>
        </div>
      </div>
      <div className="main-bar">
        <div className="main-bar-top">
          <h5>
            {selectCategoryOrDrink === "category"
              ? editCategory?.title === ""
                ? "Dodaj kategoriju"
                : "Uredi kategoriju"
              : selectCategoryOrDrink === "subCategory"
              ? editSubCategory?.title === ""
                ? "Dodaj podkategoriju"
                : "Uredi podkategoriju"
              : selectCategoryOrDrink === "drinkCombination"
              ? editDrinkCombination?.title === ""
                ? "Dodaj kominaciju"
                : "Uredi kombinaciju"
              : editDrink?.title === ""
              ? "Dodaj piće"
              : "Uredi piće"}
          </h5>
        </div>
        {selectCategoryOrDrink === "category" ? (
          <EditCategory category={editCategory} setCategory={setEditCategory} />
        ) : selectCategoryOrDrink === "subCategory" ? (
          <EditSubCategory
            subCategory={editSubCategory}
            setSubCategory={setEditSubCategory}
            categories={categories}
          />
        ) : selectCategoryOrDrink === "drinkCombination" ? (
          <EditDrinksCombination
            drinkCombination={editDrinkCombination}
            categories={categories}
            subCategories={subCategories}
            drinks={drinks}
            setDrinksCombinations={setDrinkCombinations}
            setDrinkCombination={setEditDrinkCombination}
          />
        ) : (
          <EditDrink
            drink={editDrink}
            categories={categories}
            subCategories={subCategories}
            setDrinks={setDrinks}
            setDrink={setEditDrink}
          />
        )}
      </div>
    </div>
  );
};

export default EditDrinks;
