import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Image = ({ src, alt, effect, width, height }) => {
  // const [localSrc, setLocalSrc] = useState("");

  // useEffect(() => {
  //   window.api.receive("image-fetched", (newPath) => {
  //     console.log("Received image path:", newPath);
  //     setLocalSrc(newPath);
  //   });

  //   return () => {
  //     window.api.remove("image-fetched");
  //   };
  // }, [src]);

  // console.log("Image localSrc:", localSrc);

  return (
    <LazyLoadImage
      // src={localSrc || src} // Use localSrc or fallback to original src
      src={src}
      alt={alt}
      width={width}
      height={height}
    />
  );
};

export default Image;
